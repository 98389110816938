import React, { useState, useEffect } from "react";
import "./styles.css";

export default function DemoPage() {
  const [hideSummary, setHideSummary] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHideSummary(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container">
      <header>
        <h1>Header</h1>
      </header>
      <div className="content">
        <section className="cart">
          <h2>Cart</h2>
          <ul>
            <li>Item 1</li>
            <li>Item 2</li>
            <li>Item 3</li>
            <li>Item 4</li>
            <li>Item 5</li>
            <li>Item 6</li>
            <li>Item 7</li>
            <li>Item 8</li>
            <li>Item 9</li>
            <li>Item 10</li>
            <li>Item 11</li>
            <li>Item 12</li>
            <li>Item 13</li>
            <li>Item 14</li>
            <li>Item 15</li>
            <li>Item 16</li>
            <li>Item 17</li>
            <li>Item 18</li>
            <li>Item 19</li>
            <li>Item 20</li>
          </ul>
        </section>
        <section className={`subtotal${hideSummary ? " no-padding" : ""}`}>
          <h2>Subtotal</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Product</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product 1</td>
                <td>$10</td>
              </tr>
              <tr>
                <td>Product 2</td>
                <td>$20</td>
              </tr>
              <tr>
                <td>Product 3</td>
                <td>$30</td>
              </tr>
              <tr>
                <td>Product 4</td>
                <td>$40</td>
              </tr>
              <tr>
                <td>Product 5</td>
                <td>$50</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      {!hideSummary && (
        <div className="summary">
          <h2>Payment Summary</h2>
          <p>Item 1: $10</p>
          <p>Item 2: $20</p>
          <p>Item 3: $30</p>
          <p>Item 4: $40</p>
          <p>Item 5: $50</p>
        </div>
      )}
      <div className="payment">
        <h2>Payment Button</h2>
        <button>Pay Now</button>
      </div>
    </div>
  );
}
