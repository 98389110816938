import { apiCall } from "../../lib/supabase";

export const getCategories = async (brand) => {
  const response = await apiCall
    .from("category")
    .select("*, brand!inner(id, name)")
    .eq("brand.name", brand)
    .order("sequence", { ascending: true });
  return response;
};

export const getCategoryByName = async (categoryName) => {
  const response = await apiCall
    .from("category")
    .select("id,name")
    .ilike("name", `%${categoryName}%`)
    .limit(1);

  return response;
};
