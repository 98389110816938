import { Image as ImageBT, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./css/menuDetail.module.css";
import { useEffect, useState } from "react";
import checkIfImageExists from "../../utils/checkIfImageExists";

const MenuDetail = ({ show, onHide, data, placeOrder, productsSupabase }) => {
  const cartData = useSelector((state) => state.cart.cart);
  const cartItemData = cartData.filter((item) => data.item_no === item.id);
  const addToCart = (data) => {
    placeOrder(data);
    onHide();
  };

  const findProduct = productsSupabase?.find(
    (record) => record?.name === data?.item_name
  );

  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`;

  const [imageUrl, setImageUrl] = useState(defaultImage);
  const [loading, setLoading] = useState(true);

  const originalImageUrl = findProduct?.image_url;

  useEffect(() => {
    setLoading(true);

    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageUrl(originalImageUrl);
      } else {
        setImageUrl(defaultImage);
      }
    });

    setLoading(false);
  }, [defaultImage, originalImageUrl]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered={true}
      dialogClassName={styles.bottomModal}>
      <Modal.Header closeButton>
        {/* <Modal.Title>{data.item_desc}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!loading && (
            <ImageBT
              fluid
              src={imageUrl}
              style={{
                minHeight: 180,
                objectFit: "contain",
              }}
            />
          )}
        </div>
        <p style={{ fontWeight: "600", marginTop: "24px" }}>{data.item_desc}</p>
        <p style={{ fontWeight: "800" }}>
          $
          {Number(
            data?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </p>
        {findProduct?.description && (
          <p style={{ textTransform: "lowercase", margin: 0 }}>
            {findProduct?.description}
          </p>
        )}
        {/* <img
          src={`https://imagedelivery.net/0btclAJWxGAPfkBxQs1olg/${process.env.REACT_APP_MODE}/${process.env.REACT_APP_MODE}_${data.item_no}.jpg`}
          className="carousel-cls"
          alt=""
        /> */}
        {/* <span className="menu-price-text mt-2">
          $
          {Number(
            data?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </span> */}
      </Modal.Body>
      <Modal.Footer>
        {data.is_soldout === "Y" ||
        !data.is_soldout ||
        data.is_emenu_disable === "Y" ||
        !data.is_emenu_disable ||
        (data.is_avl_limit_check === "Y" && data.bal_qty === 0) ||
        !data.is_avl_limit_check ? (
          <button className="sold-out-btn mb-2 mt-4" disabled>
            SOLD OUT
          </button>
        ) : (
          <>
            {cartItemData &&
            cartItemData.length > 0 &&
            cartItemData[0].qty >= data.bal_qty &&
            data.bal_qty !== 0 ? (
              <button className="sold-out-btn mb-2 mt-auto flex-end" disabled>
                ADD
              </button>
            ) : (
              <button
                className="border-btn add-cart-btn d-flex justify-content-center align-items-center shaking-button mb-2 mt-auto flex-end"
                onClick={() => addToCart(data)}>
                ADD
              </button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default MenuDetail;
