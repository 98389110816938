import React, { useEffect, useState } from "react";
import { BiPencil } from "react-icons/bi";
import { BsDashLg, BsFillTrash3Fill, BsPlusLg } from "react-icons/bs";
import CartItemImage from "./CartItemImage";

const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

const CartItem = ({
  cartItem,
  setConfirmData,
  deleteCart,
  placeOrder,
  decrementCounter,
  incrementCounter,
  productsSupabase,
}) => {
  return (
    <div className="bg-white">
      <div className="d-flex justify-content-between ps-4 pb-2 pe-3 pt-3">
        <div className="d-grid text-start">
          <label className="cart-item-text mb-2 text-uppercase">
            {cartItem.item.item_desc}
          </label>
          {cartItem?.item?.promo_name && (
            <span className="promo-name">
              Promotion: {cartItem?.item?.promo_name}
            </span>
          )}
          {cartItem.addOnValues &&
            cartItem.addOnValues.length > 0 &&
            cartItem.addOnValues.map((custom, i) => (
              <>
                <label key={i} className="modifier-text mb-1 text-uppercase">
                  {custom.modifier_name}: {custom.citem_name}{" "}
                  {custom?.modifier_qty > 1 && `x${custom?.modifier_qty}`}
                </label>
              </>
            ))}

          <label className="cart-item-price mb-1 mt-2">
            ${Number(cartItem.price * cartItem.qty).toFixed(2)}
          </label>
        </div>
        <div>
          {productsSupabase?.length > 0 && (
            <CartItemImage
              data={cartItem?.item}
              productsSupabase={productsSupabase}
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between ps-4 pb-2 pe-3 align-items-center">
        <div className="d-flex">
          <BsFillTrash3Fill
            className="material-icons me-2"
            onClick={() =>
              setConfirmData({
                open: true,
                title: "Remove item",
                description: "Do you want to remove this item?",
                onConfirm: () => {
                  deleteCart(cartItem.uuid);
                  setConfirmData({});
                },
              })
            }
          />

          {cartItem.item.itemmaster_menutype_grpdtls === "" ? (
            <div className="d-flex">
              {/* <label className="public-sans-font fw-normal font-16 black-text line-height-17 mb-1 mt-2 edit-btn-disable">
                              <BiPencil className="font-18 pe-1" />
                              Edit
                            </label> */}
            </div>
          ) : (
            <label
              className="mt-1 edit-btn d-flex justify-content-center text-uppercase"
              onClick={() => placeOrder(cartItem.uuid)}>
              <BiPencil className="font-18 pe-1" />
            </label>
          )}
        </div>
        <div className="mb-1 mt-2">
          <BsDashLg
            className="qty-class me-3"
            onClick={() =>
              cartItem.qty === 1
                ? setConfirmData({
                    open: true,
                    title: "Remove item",
                    description: "Do you want to remove this item?",
                    onConfirm: () => {
                      decrementCounter(cartItem.uuid);
                      setConfirmData({});
                    },
                  })
                : decrementCounter(cartItem.uuid)
            }
          />
          <label className="cart-item-qty">{cartItem.qty}</label>
          <BsPlusLg
            className="qty-class ms-3"
            onClick={() => {
              if (
                !(
                  cartItem.qty >= cartItem.item.bal_qty &&
                  cartItem.item.bal_qty !== 0
                )
              ) {
                incrementCounter(cartItem.uuid);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
