import { getBrandByName } from "../services/supabase/brand";

const getBrandImage = async () => {
  const resp = await getBrandByName(process.env.REACT_APP_MODE);
  return {
    error: resp.error,
    data: !resp.error ? resp.data[0].logo_url : null,
  };
};

export default getBrandImage;
