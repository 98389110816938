import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import {
  BsFillCaretUpFill,
  BsFillCaretDownFill,
  BsPlus,
  BsFillCheckCircleFill,
  BsDashLg,
  BsPlusLg,
} from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateToCart } from "../../redux/cartSlice";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import get from "lodash/get";
import { formatCents } from "../../utils/numberUtils";
import { STORAGEKEY } from "../../constants";
import CustomizeMinQtyOrder from "./CustomizeMinQtyOrder";
import _, { isArray } from "lodash";
import useBrandConfig from "../../hooks/useBrandConfig";
import usePromotion from "../../hooks/usePromotion";

export const CustomizeModal = ({
  showModal,
  setShowModal,
  showModalData: customizeData,
  onSuccessSubmit,
  redirectAfterSubmit = true,
}) => {
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const cartData = useSelector((state) => state.cart.cart);

  const { config } = useBrandConfig();
  const { handleConvertPromotionItem } = usePromotion();

  const selectedDineOption = localStorage.getItem(STORAGEKEY.DINING_MODE);

  const [breakpointQty, setBreakpointQty] = useState(false);

  const qtyRef = useRef();
  const nameRef = useRef();

  /**
   * * GET selected dine option
   */
  const isDineIn = useMemo(
    () => selectedDineOption === "dinein",
    [selectedDineOption]
  );

  const [menuTypeGroupIndex, setMenuTypeGroupIndex] = useState(1);

  const quantity = useRef(customizeData.qty ? customizeData.qty : 1);

  const validModifierCheck = (value, valueItem = []) => {
    let maxAvlQty = customizeData.item?.itemmaster_menutype_grpdtls.filter(
      (item) => value.modifier_name === item.modifier_name
    )[0].max_qty;

    let selectedQty = 0;
    valueItem?.forEach((item) => {
      selectedQty += customizeData.item?.itemmaster_menutypedtls.filter(
        (modItem) => modItem.citem_name === item
      )[0].qty;
    });

    return maxAvlQty === selectedQty;
  };

  /**
   * * Group list by {field}
   *
   * @param {*} addOns
   * @param {*} field
   * @returns
   */
  const groupListByField = (addOns, field) =>
    addOns?.reduce(function (a, e) {
      // GROUP BY estimated key (estKey), well, may be a just plain key
      // a -- Accumulator result object
      // e -- sequentally checked Element, the Element that is tested just at this itaration

      // new grouping name may be calculated, but must be based on real value of real field
      let estKey = e[field];

      (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
      return a;
    }, {});

  /**
   * * Generate initial formik values
   */
  const initialValues = useMemo(() => {
    const initVal = {};

    get(customizeData, "item.itemmaster_menutype_grpdtls", []).forEach((el) => {
      if (el.max_qty === 1) {
        // * Radio
        // * check is it has selected add-on values?

        const originalVal = customizeData?.addOnValues
          ? customizeData?.addOnValues.filter(
              (addOn) => addOn?.modifier_name === el?.modifier_name
            )?.[0]?.citem_name
          : customizeData?.item?.itemmaster_menutypedtls?.filter(
              (customItems) => customItems?.modifier_name === el?.modifier_name
            )?.[0];
        const val = originalVal?.citem_name;

        if (originalVal?.is_default !== "N") {
          initVal[el?.modifier_name] = val;
        }
      } else {
        if (groupListByField(customizeData?.addOnValues, "modifier_name")) {
          // * Checkbox
          initVal[el.modifier_name] = groupListByField(
            customizeData?.addOnValues,
            "modifier_name"
          )
            [customizeData?.modifier_name]?.map((it) =>
              Array.from(Array(it?.modifier_qty), (_, x) => x)
                .flat(Infinity)
                .map((o) => it?.citem_name)
            )
            .flat(Infinity);
        } else {
          const originalValArray =
            customizeData?.item?.itemmaster_menutypedtls?.filter(
              (customItems) => customItems?.modifier_name === el?.modifier_name
            );
          const defaultValueArray = [];

          originalValArray?.forEach((record) => {
            if (record?.is_default === "Y") {
              defaultValueArray.push(record?.citem_name);
            }
          });

          if (defaultValueArray?.length > 0) {
            initVal[el?.modifier_name] = defaultValueArray;
          }
        }
      }
    });

    return initVal;
  }, [customizeData]);

  /**
   * * Generate formik validation schema
   */
  const validationSchema = useMemo(() => {
    const schema = {};
    get(customizeData, "item.itemmaster_menutype_grpdtls", []).forEach((el) => {
      if (el?.is_optional !== "Y") {
        if (el.max_qty === 1) {
          schema[el.modifier_name] = Yup.string().required(
            `${el.modifier_name} is required`
          );
        } else if (el.max_qty > 0) {
          schema[el.modifier_name] = Yup.array().test(
            "maxQtyTest",
            `Please select ${el.max_qty} ${el.modifier_name}`,
            (valueItem) => validModifierCheck(el, valueItem)
          );
        } else {
          schema[el.modifier_name] = Yup.array().nullable().notRequired();
        }
      }
    });

    return schema;
  }, [customizeData, validModifierCheck]);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => {
      let addOnValues = [];
      formik.validateForm();
      if (Object.keys(values) && Object.keys(values)?.length > 0) {
        for (const addOn of Object.keys(values)) {
          customizeData.item?.itemmaster_menutypedtls
            .filter(
              (item) =>
                item.modifier_name === addOn &&
                (isArray(values[addOn])
                  ? values[addOn]?.includes(item.citem_name)
                  : values[addOn] === item.citem_name)
            )
            .map((addOnData) =>
              addOnValues.push({
                modifier_qty: isArray(values[addOn])
                  ? values[addOn].filter(
                      (addonName) => addonName === addOnData.citem_name
                    ).length
                  : 1,
                item_no: addOnData.citem_no,
                modifier_name: addOnData.modifier_name,
                citem_name: addOnData.citem_name,
                price_dtls: isDineIn
                  ? addOnData.price_dtls[0].dine_in_price
                  : addOnData.price_dtls[0].takeaway_price,
                sku_no: addOnData.citem_no.replace("PRD", "SKU"),
                uom: addOnData.uom,
              })
            );
        }
      }

      const itemInCart = cartData.find(
        (item) =>
          item.id === customizeData.item.item_no &&
          item.addOnValues.length === 0 &&
          _.isEqual(
            _.map(item.addOnValues, "item_no"),
            _.map(addOnValues, "item_no")
          )
      );

      if (customizeData.qty) {
        dispatcher(
          updateToCart({
            id: customizeData.item.item_no,
            uuid: customizeData.uuid,
            addOnValues: addOnValues,
            item: customizeData.item,
            qty: quantity.current,
            price: price.original,
            remarks: values.remarks,
          })
        );
      } else {
        dispatcher(
          addToCart(
            handleConvertPromotionItem(
              {
                uuid: itemInCart?.uuid,
                id: customizeData.item.item_no,
                addOnValues: addOnValues,
                item: customizeData.item,
                qty: quantity.current,
                price: price.original,
                remarks: values.remarks,
              },
              true
            )
          )
        );
      }
      setShowModal(false);
      if (redirectAfterSubmit) {
        navigate("/home", {
          state: {
            addOnValues: addOnValues,
            item: customizeData.item,
            qty: quantity.current,
            price: price.original,
            remarks: values.remarks,
          },
        });
      }
      if (onSuccessSubmit) {
        onSuccessSubmit(customizeData.item);
      }
    },
  });

  const toggleComment = (id, values) => {
    if (values === "up") {
      setMenuTypeGroupIndex((prev) => (prev.commentShown === id ? id : ""));
    } else {
      setMenuTypeGroupIndex((prev) => (prev.commentShown !== id ? id : ""));
    }
  };

  const getQtyByModifier = (modifierName) => {
    return typeof formik.values[modifierName] === "string"
      ? 1
      : formik.values[modifierName]?.length;
  };

  /**
   * * GET grouped menu type list
   */
  const getMenuTypeGrouped = useCallback((data) => {
    return get(data, "item.itemmaster_menutype_grpdtls", [])
      .slice()
      .sort((a, b) => a.item_menutype_grpdtls - b.item_menutype_grpdtls)
      .map((menuTypeGroup, i) => menuTypeGroup);
  }, []);

  /**
   * * GET menu type list
   */
  const getMenuType = useCallback((data, modifierName) => {
    return get(data, "item.itemmaster_menutypedtls", []).filter(
      (customItems) => customItems.modifier_name === modifierName
    );
  }, []);

  /**
   *  * GET selected items
   */
  const selectedItems = useMemo(() => {
    const selectedItems = [];
    Object.keys(formik.values).map((item) => {
      if (typeof formik.values[item] === "string") {
        selectedItems.push(
          ...customizeData?.item?.itemmaster_menutypedtls.filter(
            (it) =>
              it.modifier_name === item && it.citem_name === formik.values[item]
          )
        );
      } else if (typeof formik.values[item] === "object") {
        formik.values[item].map((it) => {
          selectedItems.push(
            ...customizeData.item?.itemmaster_menutypedtls?.filter(
              (record) =>
                record?.citem_name === it && record?.modifier_name === item
            )
          );
        });
      }
    });

    return selectedItems;
  }, [customizeData, formik?.values]);

  /**
   *  * GET total price
   */
  const price = useMemo(() => {
    const price = selectedItems.reduce((total, item) => {
      return (
        total +
        item.price_dtls[0][isDineIn ? "dine_in_price" : "takeaway_price"] *
          item.qty
      );
    }, 0);

    return {
      original: price, // original price number
      formatted: formatCents(price), // Formatted  to display
    };
  }, [selectedItems]);

  const handleRadioButtonSelected = (e, currAccordionId) => {
    formik.handleChange(e);
    toggleComment(currAccordionId, "up");
    toggleComment(currAccordionId + 1, "down");
  };

  const incrementCounter = (modifierName, citemName) => {
    formik.setValues({
      ...formik.values,
      [modifierName]: [...formik.values[modifierName], citemName],
    });
  };

  const decrementCounter = (modifierName, citemName) => {
    const allElExceptTarget = formik.values[modifierName].filter(
      (it) => it !== citemName
    );

    const targetEl = formik.values[modifierName].filter(
      (it) => it === citemName
    );

    if (targetEl.length > 1) {
      targetEl.shift();
    }

    formik.setValues({
      ...formik.values,
      [modifierName]: [...allElExceptTarget, ...targetEl],
    });
  };

  const getQtyItem = (modifierName, citemName) =>
    formik.values[modifierName].filter((it) => it === citemName).length;

  const getGroupQty = (modifierName, exceptCitemName) =>
    Array.isArray(formik.values[modifierName])
      ? formik.values[modifierName].filter((it) => it !== exceptCitemName)
          .length
      : 1;

  const handleResizeQty = () => {
    const windowWidth = window.innerWidth;

    if (
      windowWidth < 500 ||
      windowWidth - 160 - nameRef?.current?.clientWidth <
        qtyRef?.current?.clientWidth
    ) {
      setBreakpointQty(true);
    } else {
      setBreakpointQty(false);
    }
  };

  useEffect(() => {
    handleResizeQty();

    window.addEventListener("resize", handleResizeQty);

    return () => {
      window.removeEventListener("resize", handleResizeQty);
    };
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      className="customize-content">
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton className="modal-border" closeVariant="black">
          <Modal.Title>
            <div className="d-grid">
              <label className="customize-header">
                {customizeData.item.brand_name}
              </label>
              <label className="customize-header">S{price.formatted}</label>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div className="d-flex justify-content-end">
              {/* <BsFillCircleFill /> */}
              <label className="customize-header">S{price.formatted}</label>
            </div>

            <div>
              {getMenuTypeGrouped(customizeData).map((menuTypeGroup, i) => (
                <Fragment key={i}>
                  {/* {formik.errors[menuTypeGroup.modifier_name] ? (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                      Please select {menuTypeGroup.modifier_name}
                    </p>
                  ) : null} */}
                  <div
                    style={
                      formik.values[menuTypeGroup.modifier_name] === ""
                        ? { opacity: "0.6" }
                        : {}
                    }
                    className={cx(
                      "category-option d-flex justify-content-between mb-1",
                      {
                        selected:
                          getQtyByModifier(menuTypeGroup.modifier_name) > 0 &&
                          !formik.errors[menuTypeGroup.modifier_name],
                      }
                    )}>
                    <div className="ms-3 pt-1 d-flex flex-row justify-content-center align-items-center gap-1">
                      {formik.values[menuTypeGroup.modifier_name] === "" ? (
                        <div className="badge-number-qty">{i + 1}</div>
                      ) : (
                        <BsFillCheckCircleFill
                          style={{ fontSize: "20px", color: "white" }}
                        />
                      )}
                      <label className="ms-1 text-white font-16 fw-normal">
                        {menuTypeGroup.modifier_name}
                      </label>
                    </div>
                    {menuTypeGroupIndex ===
                    menuTypeGroup.item_menutype_grpdtls ? (
                      <BsFillCaretDownFill
                        className="me-3 pt-1"
                        style={{ fontSize: "33px", color: "white" }}
                        onClick={() =>
                          toggleComment(
                            menuTypeGroup.item_menutype_grpdtls,
                            "up"
                          )
                        }
                      />
                    ) : (
                      <BsFillCaretUpFill
                        className="me-3 pt-1"
                        style={{ fontSize: "33px", color: "white" }}
                        onClick={() =>
                          toggleComment(
                            menuTypeGroup.item_menutype_grpdtls,
                            "down"
                          )
                        }
                      />
                    )}
                  </div>
                  {menuTypeGroupIndex ===
                  menuTypeGroup.item_menutype_grpdtls ? (
                    <>
                      {menuTypeGroup.max_qty === 1 ? (
                        <>
                          <div className="mb-3">
                            <label className="mt-2 font-16 fw-normal">
                              Choose max {menuTypeGroup.max_qty}
                            </label>
                            {getMenuType(
                              customizeData,
                              menuTypeGroup.modifier_name
                            ).map((menuType, index) => (
                              <Fragment key={index}>
                                <CustomizeMinQtyOrder
                                  index={index}
                                  menuType={menuType}
                                  menuTypeGroup={menuTypeGroup}
                                  formik={formik}
                                  selectedDineOption={selectedDineOption}
                                  handleRadioButtonSelected={
                                    handleRadioButtonSelected
                                  }
                                />
                              </Fragment>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-3">
                            <label className="mt-2 font-16 fw-normal">
                              {menuTypeGroup.max_qty > 0 &&
                                `Choose max ${menuTypeGroup.max_qty}`}
                            </label>
                            <span className="font-14 d-flex ps-3 text-danger">
                              {formik &&
                                formik.errors &&
                                Object.keys(formik.errors)?.length > 0 &&
                                formik.errors[menuTypeGroup.modifier_name]}
                            </span>
                            {customizeData.item?.itemmaster_menutypedtls
                              .filter(
                                (customItems) =>
                                  customItems.modifier_name ===
                                  menuTypeGroup.modifier_name
                              )
                              .filter(
                                (record) => record?.is_emenu_disable !== "Y"
                              )
                              .map((menuType, index) => (
                                <>
                                  <div className="d-flex justify-content-between mt-2">
                                    <Form.Group
                                      ref={nameRef}
                                      className="pe-4"
                                      key={index}>
                                      <div className="d-flex flex-column gap-1">
                                        <Form.Check
                                          type="checkbox"
                                          // id={`${menuType.modifier_name}`}
                                          id={index}
                                          name={`${menuType.modifier_name}`}
                                          label={`${menuType.citem_name}`}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              if (
                                                menuTypeGroup.max_qty !== 0 &&
                                                formik.values[
                                                  menuType.modifier_name
                                                ]?.length >=
                                                  menuTypeGroup.max_qty
                                              ) {
                                                e.preventDefault();
                                                return;
                                              }
                                            } else {
                                            }

                                            if (e.target.checked) {
                                              formik.handleChange(e);
                                            } else {
                                              formik.setFieldValue(
                                                menuType.modifier_name,
                                                formik.values?.[
                                                  menuType.modifier_name
                                                ]?.filter(
                                                  (record) =>
                                                    record !==
                                                    menuType.citem_name
                                                )
                                              );
                                            }
                                          }}
                                          value={menuType.citem_name}
                                          checked={formik.values[
                                            menuType.modifier_name
                                          ].includes(menuType.citem_name)}
                                          className="public-sans-font line-height-25 font-14 fw-bold black-text test-start text-uppercase"
                                          disabled={
                                            menuTypeGroup.max_qty !== 0 &&
                                            formik.values[
                                              menuType.modifier_name
                                            ]?.length >=
                                              menuTypeGroup.max_qty &&
                                            !formik.values[
                                              menuType.modifier_name
                                            ].includes(menuType.citem_name)
                                          }
                                        />
                                        {breakpointQty &&
                                          formik.values?.[
                                            menuType?.modifier_name
                                          ]?.includes(menuType?.citem_name) && (
                                            <div className="left-mb-1">
                                              <BsDashLg
                                                className="qty-class me-3"
                                                onClick={() =>
                                                  decrementCounter(
                                                    menuType?.modifier_name,
                                                    menuType?.citem_name
                                                  )
                                                }
                                              />
                                              <label className="cart-item-qty">
                                                {getQtyItem(
                                                  menuType?.modifier_name,
                                                  menuType?.citem_name
                                                )}
                                              </label>
                                              <BsPlusLg
                                                className={cx(
                                                  "qty-class ms-3",
                                                  {
                                                    "qty-class-disable":
                                                      menuType.max_qty !== 0 &&
                                                      getQtyItem(
                                                        menuType?.modifier_name,
                                                        menuType?.citem_name
                                                      ) === menuType.max_qty,
                                                  }
                                                )}
                                                onClick={() => {
                                                  if (
                                                    menuType.max_qty !== 0 &&
                                                    getQtyItem(
                                                      menuType?.modifier_name,
                                                      menuType?.citem_name
                                                    ) === menuType.max_qty
                                                  ) {
                                                    return;
                                                  }

                                                  if (
                                                    menuTypeGroup?.group_limit ===
                                                    0
                                                  ) {
                                                    return incrementCounter(
                                                      menuType?.modifier_name,
                                                      menuType?.citem_name
                                                    );
                                                  }

                                                  if (
                                                    getQtyItem(
                                                      menuType?.modifier_name,
                                                      menuType?.citem_name
                                                    ) +
                                                      getGroupQty(
                                                        menuType?.modifier_name,
                                                        menuType?.citem_name
                                                      ) <
                                                    menuTypeGroup?.group_limit
                                                  ) {
                                                    return incrementCounter(
                                                      menuType?.modifier_name,
                                                      menuType?.citem_name
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    </Form.Group>
                                    <span className="customize-item-citen-name pe-4 text-uppercase d-flex flex-row justify-content-between gap-2">
                                      {!breakpointQty &&
                                        formik.values?.[
                                          menuType?.modifier_name
                                        ]?.includes(menuType?.citem_name) && (
                                          <div
                                            ref={qtyRef}
                                            className="left-mb-1">
                                            <BsDashLg
                                              className="qty-class me-3"
                                              onClick={() =>
                                                decrementCounter(
                                                  menuType?.modifier_name,
                                                  menuType?.citem_name
                                                )
                                              }
                                            />
                                            <label className="cart-item-qty">
                                              {getQtyItem(
                                                menuType?.modifier_name,
                                                menuType?.citem_name
                                              )}
                                            </label>
                                            <BsPlusLg
                                              className={cx("qty-class ms-3", {
                                                "qty-class-disable":
                                                  menuType.max_qty !== 0 &&
                                                  getQtyItem(
                                                    menuType?.modifier_name,
                                                    menuType?.citem_name
                                                  ) === menuType.max_qty,
                                              })}
                                              onClick={() => {
                                                if (
                                                  menuType.max_qty !== 0 &&
                                                  getQtyItem(
                                                    menuType?.modifier_name,
                                                    menuType?.citem_name
                                                  ) === menuType.max_qty
                                                ) {
                                                  return;
                                                }

                                                if (
                                                  menuTypeGroup?.group_limit ===
                                                  0
                                                ) {
                                                  return incrementCounter(
                                                    menuType?.modifier_name,
                                                    menuType?.citem_name
                                                  );
                                                }

                                                if (
                                                  getQtyItem(
                                                    menuType?.modifier_name,
                                                    menuType?.citem_name
                                                  ) +
                                                    getGroupQty(
                                                      menuType?.modifier_name,
                                                      menuType?.citem_name
                                                    ) <
                                                  menuTypeGroup?.group_limit
                                                ) {
                                                  return incrementCounter(
                                                    menuType?.modifier_name,
                                                    menuType?.citem_name
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        )}
                                      <label>
                                        {selectedDineOption === "takeaway" ? (
                                          <span className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text text-uppercase">
                                            {menuType?.price_dtls[0]
                                              ?.dine_in_price > 0
                                              ? `+$${(menuType?.price_dtls[0]?.takeaway_price).toFixed(
                                                  2
                                                )}`
                                              : ""}
                                          </span>
                                        ) : (
                                          <span className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text text-uppercase">
                                            {menuType?.price_dtls[0]
                                              ?.dine_in_price > 0
                                              ? `+$${(menuType?.price_dtls[0]?.dine_in_price).toFixed(
                                                  2
                                                )}`
                                              : ""}
                                          </span>
                                        )}
                                      </label>
                                    </span>
                                  </div>
                                </>
                              ))}
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </Fragment>
              ))}
            </div>
          </div>
          {config?.enableRemarks && (
            <div>
              <span className="mt-2 text-uppercase fw-bold">
                Remarks / To Note
              </span>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter remarks"
                  name="remarks"
                  onChange={formik.handleChange}
                  value={formik.values.remarks}
                  maxLength={100}
                  className="public-sans-font line-height-25 font-14 line-height-20 black-text"
                />
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="fill-cancel-btn w-100 fill-green-btn cart-btn text-uppercase fw-bold"
            type="submit"
            disabled={!formik.isValid}>
            Add
            <BsPlus style={{ fontSize: "25px", color: "white" }} />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
