// ** Icon Imports
import { Icon as IconifyIcon } from "@iconify/react";
import { Image } from "react-bootstrap";

const Icon = ({ type, icon, ...rest }) => {
  return type === "svg" ? (
    <Image
      src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
      height="24px"
      width="24px"
      {...rest}
    />
  ) : (
    <IconifyIcon
      icon={`${type}:${icon}`}
      height="16px"
      fontSize="1.25rem"
      {...rest}
    />
  );
};

export default Icon;
