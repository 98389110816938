import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    config: {},
    welcomeBanner: "",
    iconConfig: {},
  },
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setWelcomeBanner: (state, action) => {
      state.welcomeBanner = action.payload;
    },
    setIconConfig: (state, action) => {
      state.iconConfig = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const { setConfig, setWelcomeBanner, setIconConfig } = appSlice.actions;
