import React, { useEffect, useState } from "react";
import checkIfImageExists from "../../utils/checkIfImageExists";

const CartItemImage = ({ productsSupabase, data }) => {
  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo.png`;

  const [imageUrl, setImageUrl] = useState();

  const findProduct = productsSupabase?.find(
    (record) => record?.name === data?.item_name
  );

  const originalImageUrl = findProduct?.image_url;

  useEffect(() => {
    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageUrl(originalImageUrl);
      } else {
        setImageUrl(defaultImage);
      }
    });
  }, [originalImageUrl]);

  return imageUrl && <img src={imageUrl} alt="" className="cart-img" />;
};

export default CartItemImage;
