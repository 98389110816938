import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../services/supabase/category";

function HeaderMenu(props) {
  const { navRefs, menuItem, menuDish, scrollTo, allShowCategories } = props;
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(
    allShowCategories?.[0]
  );

  const headerRef = useRef();

  // category image
  const fetchSupabaseCategories = async () => {
    setIsLoading(true);
    const resp = await getCategories(process.env.REACT_APP_MODE);
    setAllCategories(resp.data);
  };
  useEffect(() => {
    fetchSupabaseCategories();
  }, []);

  // function to get the category image based on name
  const getCategoryImage = (categoryName) => {
    const category = allCategories.find(
      (category) => category.name === categoryName
    );
    // console.log("category image", category);
    return category?.image_url;
  };
  // function to get the category short name based on name
  const getCategoryShortName = (categoryName) => {
    const category = allCategories.find(
      (category) => category.name === categoryName
    );
    return category?.short_name;
  };

  // handle click of item in menu and navigate to the page with item as state
  const handleMenuItemClick = (category, index) => {
    // navigate("/category", {
    //   state: { category: category },
    // });
    // navigate to the category with anchor tag
    scrollTo?.(index);
    navigate(`#${category}`);
    setCurrentCategory(category);
    // window.location.href = `#${category}`;
  };

  // function insertLineBreak(category) {
  //   if (category.includes("(")) {
  //     return category.replace("(", "\n(");
  //   } else {
  //     return category;
  //   }
  // }

  const calculateFullWidth = () => {
    if (headerRef?.current) {
      const { offsetWidth } = headerRef?.current;
      const formatOffsetWidth = offsetWidth - (menuItem?.length - 1) * 10;

      const usingCustomWidth =
        Math.floor(formatOffsetWidth / (menuItem?.length * 80)) > 0;
      const widthItem = usingCustomWidth
        ? `${formatOffsetWidth / menuItem?.length}px`
        : "100px";

      return widthItem;
    }
  };

  return (
    <>
      <div ref={headerRef} className="d-flex header-menu">
        {allShowCategories.map((category, index) => {
          return (
            <div
              key={index}
              ref={navRefs?.current?.[index]}
              onClick={() => {
                handleMenuItemClick(category, index);
              }}
              className="header-menu-item d-flex flex-column align-items-center"
              style={{
                minWidth: calculateFullWidth(),
              }}>
              <div className="image-container">
                <img
                  src={
                    getCategoryImage(category) ||
                    `/assets/${process.env.REACT_APP_MODE}/logo/logo.png`
                  }
                  alt="Your Alt Text"
                  className="header-category-image"
                />
              </div>
              <p
                style={{
                  fontWeight: currentCategory === category ? 700 : 400,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "100%",
                  marginBottom: 0,
                  marginTop: "6px",
                }}>
                {/* {insertLineBreak(category)} */}
                {/* use short name for category if present, else use category */}
                {getCategoryShortName(category) || category}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default HeaderMenu;
