import { apiCall } from "../../lib/supabase";

export const getSessionId = async () => {
  const response = await apiCall.functions.invoke(
    `session-id?brandName=${process.env.REACT_APP_MODE}`,
    {
      body: {
        name: "Functions",
      },
    }
  );

  return response;
};
