import { STORAGEKEY } from "../../constants";
import { apiCall } from "../../lib/supabase";

export const sendPosLog = async (salesNo, payload) => {
  const response = await apiCall.functions.invoke(
    `pos-log?brandName=${
      process.env.REACT_APP_MODE
    }&salesNo=${salesNo}&storeName=${localStorage.getItem(
      STORAGEKEY.STORE_NAME
    )}`,
    {
      body: {
        name: "Functions",
        payload,
      },
    }
  );

  return response;
};
