import React from "react";
import { Carousel } from "react-bootstrap";

function CarouselBanner() {
    return (
      <Carousel className="mt-3 pe-3 ps-3">
        <Carousel.Item>
          <img
          className="carosel-img-size w-100"
            src={`/assets/${process.env.REACT_APP_MODE}/carosel/c1.png`}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
          className="carosel-img-size w-100"
            src={`/assets/${process.env.REACT_APP_MODE}/carosel/c2.png`}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
          className="carosel-img-size w-100"
            src={`/assets/${process.env.REACT_APP_MODE}/carosel/c3.png`}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    );
  }
  export default CarouselBanner;