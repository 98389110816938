import { apiCall } from "../../lib/supabase";

export const getUpsellingByTargetId = async (
  categoryTargetId,
  itemTargetId,
  outletId
) => {
  const response = await apiCall
    .from("upselling")
    .select("*")
    .or(`target.eq.${categoryTargetId},target.eq.${itemTargetId}`)
    .eq("outlet", outletId);

  return response;
};
