import React from "react";
import Header from "../../component/header";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

const Login = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState(true);
  const formik = useFormik({
    initialValues: {
      phoneno: "",
    },
    validationSchema: Yup.object({
      phoneno: Yup.string()
        .max(10, "Must be 10 number")
        .min(10, "Must be 10 number")
        .required("Phone number is required"),
    }),
    onSubmit: (values) => {
      console.log("Form Values :", values);
      navigate("/send-otp", {
        state: { phoneno: values.phoneno, name: "login" },
      });
    },
  });
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Login as Member" />
          <div className="inner-div main-div position-absolute w-100">
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
              alt="Logo"
              className="mt-4 login-logo"
            />
            <label className="d-flex justify-content-center mt-3 public-sans-font fw-bold font-28">
              Welcome back!
            </label>
            <div className="p-4 mb-4">
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="phoneno">
                  <Form.Label className="d-flex font-family-class fw-normal font-14 line-height-21">
                    Phone Number
                  </Form.Label>
                  <div className="d-flex">
                    <span className="country-code me-2 font-family-class bg-white fw-normal font-14">
                      +65
                    </span>{" "}
                    <Form.Control
                      name="phoneno"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneno}
                      placeholder="Insert phone number"
                      className="font-family-class fw-normal font-14 line-height-21"
                    />
                  </div>
                  <Form.Text>
                    {formik.touched.phoneno && formik.errors.phoneno ? (
                      <div className="text-danger font-family-class fw-normal font-12 line-height-17 mt-2">
                        {formik.errors.phoneno}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
                <Button
                  className="fill-green-btn mt-4 font-family-class fw-bold font-14 line-height-21 letter-spacing-02"
                  type="submit"
                  onClick={(values) => setFormState(values)}
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
