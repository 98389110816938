import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

const CustomizeMinQtyOrder = ({
  index,
  menuType,
  menuTypeGroup,
  formik,
  selectedDineOption,
  handleRadioButtonSelected,
}) => {
  useEffect(() => {
    if (menuTypeGroup.max_qty === 1 && menuType?.is_default === "Y") {
      formik.setFieldValue(menuType.modifier_name, menuType.citem_name);
    }
  }, []);

  return (
    <div className="d-flex justify-content-between mt-2">
      <Form.Group className="ps-3 pe-4 me-4" key={index}>
        <div>
          <Form.Check
            type="radio"
            id={index}
            name={`${menuType.modifier_name}`}
            label={`${menuType.citem_name}`}
            onChange={(e) =>
              handleRadioButtonSelected(e, menuTypeGroup.item_menutype_grpdtls)
            }
            value={menuType.citem_name}
            checked={
              formik.values[menuType.modifier_name] === `${menuType.citem_name}`
                ? true
                : false
            }
            className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text text-uppercase me-5"
          />
        </div>
      </Form.Group>
      <label>
        {selectedDineOption === "takeaway" ? (
          <span className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text position-absolute end-0 pe-4 text-uppercase">
            {menuType?.price_dtls[0]?.dine_in_price > 0
              ? `+$${(menuType?.price_dtls[0]?.takeaway_price).toFixed(2)}`
              : ""}
          </span>
        ) : (
          <span className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text position-absolute end-0 pe-4 text-uppercase">
            {menuType?.price_dtls[0]?.dine_in_price > 0
              ? `+$${(menuType?.price_dtls[0]?.dine_in_price).toFixed(2)}`
              : ""}
          </span>
        )}
      </label>
    </div>
  );
};

export default CustomizeMinQtyOrder;
