import React, { useEffect } from "react";
import ButtonOption from "../../component/ButtonOption/ButtonOption";
import { isEmpty } from "lodash";

const CustomizeDishItem = ({
  stepperMenuIcon,
  customData,
  formik,
  index,
  custom,
  defaultIcon,
  dineOption,
  isDefaultSelect,
}) => {
  const currentStepperMenuIcon =
    stepperMenuIcon?.[
      customData.modifier_name
        .replace(/[^\w\s]/gi, "")
        ?.split(" ")
        ?.join("")
        ?.split("")
        ?.map((record) => record?.toLowerCase())
        ?.join("")
    ];
  const currentMenuTypeIcon = currentStepperMenuIcon?.find((record) =>
    Array.isArray(record?.id)
      ? record?.id?.includes(customData?.citem_no)
      : record?.id === customData?.citem_no
  );
  const isSingleActive =
    formik.values[customData.modifier_name] === `${customData.citem_name}`
      ? true
      : false;

  useEffect(() => {
    if (isDefaultSelect && isEmpty(formik.values[customData.modifier_name])) {
      formik.setFieldValue(custom.modifier_name, customData.citem_name);
    }
  }, [isDefaultSelect]);

  return (
    <div className="ps-3 pe-4 pb-3">
      <div className="pt-3 pb-2 d-flex justify-content-between div-border align-items-center gap-2">
        <div className="d-flex align-items-center gap-3">
          <ButtonOption
            id={index}
            value={customData.citem_name}
            name={customData.modifier_name}
            checked={isSingleActive}
            onClick={() => {
              if (
                custom?.is_optional === "Y" &&
                formik.values?.[custom?.modifier_name] === customData.citem_name
              ) {
                formik?.setFieldValue(customData?.modifier_name, "");
                return;
              }

              formik?.setFieldValue(
                custom?.modifier_name,
                customData.citem_name
              );
            }}
            iconOptions={{
              ...(currentMenuTypeIcon || {
                type: "svg",
              }),
              icon: isSingleActive
                ? currentMenuTypeIcon?.activeIcon || defaultIcon?.activeIcon
                : currentMenuTypeIcon?.originalIcon ||
                  defaultIcon?.originalIcon,
            }}
          />
          <span className="item-name">{customData.citem_name}</span>
        </div>
        {dineOption === "takeaway" ? (
          <span className="customize-item-citen-name text-uppercase">
            {customData?.price_dtls[0]?.dine_in_price > 0
              ? `+$${(customData?.price_dtls[0]?.takeaway_price).toFixed(2)}`
              : "Free"}
          </span>
        ) : (
          <span className="customize-item-citen-name text-uppercase">
            {customData?.price_dtls[0]?.dine_in_price > 0
              ? `+$${(customData?.price_dtls[0]?.dine_in_price).toFixed(2)}`
              : ""}
          </span>
        )}
      </div>
    </div>
  );
};

export default CustomizeDishItem;
