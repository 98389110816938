import { Modal } from "react-bootstrap";
import UpsellingContent from "./UpsellingContent";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const UpsellingModal = ({ show, handleClose, recommendationItems, clearUpsellingState }) => {
  const handleCloseModal = () => {
    if(clearUpsellingState) {
      clearUpsellingState()
    }
    handleClose()
  }
  return (
    <Modal centered show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Based on your choice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UpsellingContent
          afterPlaceOrder={handleCloseModal}
          recommendationItems={recommendationItems}
          responsive={responsive}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UpsellingModal;
