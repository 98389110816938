import { createSlice } from "@reduxjs/toolkit";

const posSlice = createSlice({
  name: "auth",
  initialState: {
    posStoreStockList: "",
    posMenuButton: [],
    posFullItemList: [],
    supabaseProducts: [],
  },
  reducers: {
    setPosStoreStockList: (state, action) => {
      state.posStoreStockList = action.payload;
    },
    setPosMenuButton: (state, action) => {
      state.posMenuButton = action.payload;
    },
    setPosFullItemList: (state, action) => {
      state.posFullItemList = action.payload;
    },
    setSupabaseProducts: (state, action) => {
      state.supabaseProducts = action.payload;
    },
    resetPosState: (state) => {
      state.posStoreStockList = "";
      state.posMenuButton = [];
      state.posFullItemList = [];
      state.supabaseProducts = [];
    },
  },
});

export const posReducer = posSlice.reducer;
export const {
  setPosStoreStockList,
  setPosMenuButton,
  setPosFullItemList,
  setSupabaseProducts,
  resetPosState,
} = posSlice.actions;
