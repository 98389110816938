import React, { useEffect } from "react";
import { getBrandConfig } from "../services/supabase/brandConfig";
import { useDispatch, useSelector } from "react-redux";
import { setConfig, setIconConfig, setWelcomeBanner } from "../redux/appSlice";

const useBrandConfig = () => {
  const { config, welcomeBanner, iconConfig } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();

  const handleGetConfig = async () => {
    const { data } = await getBrandConfig();

    dispatch(setConfig(data?.config));
    dispatch(setWelcomeBanner(data?.welcomeBanner));
    dispatch(setIconConfig(data?.iconConfig));
  };

  useEffect(() => {
    handleGetConfig();
  }, []);

  return { config, welcomeBanner, iconConfig };
};

export default useBrandConfig;
