import moment from "moment";
import { useSelector } from "react-redux";
import { getProductsByCategory } from "../services/supabase/product";
import _ from "lodash";
import { useState } from "react";

const useCategory = () => {
  const {
    posStoreStockList,
    posMenuButton,
    posFullItemList,
    supabaseProducts,
  } = useSelector((state) => state.pos);

  const [stateProductByCategory, setStateProductByCategory] = useState([]);

  const itemsListAndCategory = async (allCategories, currentCategory) => {
    const resultParse = JSON.parse(posStoreStockList || "[]");

    let resultItemsParsed = resultParse.filter(
      (category) => category.avl_type === "I"
    );

    const categoryNames = allCategories.map((item) => item.name);

    return await menuButtonFunction(
      categoryNames,
      resultItemsParsed,
      resultParse,
      currentCategory
    );
  };

  const menuButtonFunction = async (
    categoryCodes,
    resultItemsParsed,
    resultParse,
    currentCategory
  ) => {
    let newCategoryCodes = categoryCodes;

    const categoryMenuButton = posMenuButton;

    let categoryMainMenu = categoryMenuButton.filter(
      (menuItem) => menuItem.category && menuItem.category.length > 0
      // (menuItem) => menuItem.root_category_code === "MAIN"
      // filter menuItem.items length > 0
      // (menuItem) => menuItem.items.length > 0
    );
    let categoryMainMenuButton = [];
    for (const catItems of categoryMainMenu) {
      categoryMainMenuButton.push(...catItems.category);
    }
    if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
      let categoriesIndex = categoryMainMenuButton;
      // categoryMainMenuButton = categoryMainMenuButton[0];
      // let categoriesIndex = categoryMainMenuButton.category;
      // ? Sort on sequences and filter on start and end time
      categoriesIndex = categoriesIndex
        .sort((a, b) => a.course_seq - b.course_seq)
        .filter(
          (category) =>
            category.start_time <= moment().format("HH:mm:ss") &&
            category.end_time > moment().format("HH:mm:ss")
        );

      const categoregoryFromApi = categoryMenuButton.map((category) => {
        return category.root_category_code;
      });

      newCategoryCodes = _.intersection(newCategoryCodes, categoregoryFromApi);
      // for (const category of categoriesIndex) {
      //   categoryCodes.push(category.category_code);
      // }
      let menuItemsArray = [];
      for (const category of newCategoryCodes) {
        const catItem = categoryMenuButton.filter(
          (catItems) => catItems.root_category_code === category
        );
        if (
          catItem &&
          catItem.length > 0 &&
          catItem[0].items &&
          catItem[0].items.length > 0
        ) {
          menuItemsArray.push(...catItem[0].items);
        }
      }
      // ? Intersect items from stock and button api
      let intersectCategory = _.intersectionBy(
        resultItemsParsed,
        menuItemsArray,
        (obj) => obj.item_category || obj.item_no
      );
      // ? Intersect items from with intersected items and button api items
      intersectCategory = Object.values(
        _.merge(
          _.keyBy(intersectCategory, "item_category"),
          _.keyBy(menuItemsArray, "item_no")
        )
      );
      // temp disable for demo
      // .filter(
      //   (item) =>
      //     item.is_emenu_disable === "N" &&
      //     item.start_time <= moment().format("HH:mm:ss") &&
      //     item.end_time > moment().format("HH:mm:ss")
      // );

      return await menuItemsFunction(
        intersectCategory,
        newCategoryCodes,
        resultParse,
        currentCategory
      );
    }
  };

  const menuItemsFunction = async (
    intersectCategory,
    categoryCodes,
    resultParse,
    currentCategory
  ) => {
    const newResultParse = resultParse?.filter(
      (record) => record?.is_emenu_disable !== "Y"
    );
    const result = posFullItemList;
    const newResult = result.filter((item) => {
      const findItem = resultParse?.find(
        (record) => record?.item_no === item?.item_no
      );

      return findItem?.is_emenu_disable !== "Y";
    });

    const filterItemMaster = newResult.filter(
      (item) =>
        item.itemmaster_menutypedtls && item.itemmaster_menutypedtls.length > 0
    );

    const stockedCheckedItem = [];
    if (filterItemMaster && filterItemMaster.length > 0) {
      for (const itemMaster of filterItemMaster) {
        let itemmaster_menutypedtls = [];
        for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
          const modifierQty = newResultParse.filter(
            (itemCategory) => itemCategory.item_no === typeDtls.citem_no
          );
          const findItem = resultParse?.find(
            (record) => record?.item_no === typeDtls.citem_no
          );
          const checkingDisabledMenu = findItem?.is_emenu_disable !== "Y";

          if (checkingDisabledMenu) {
            if (modifierQty && modifierQty.length > 0) {
              itemmaster_menutypedtls.push({
                ...typeDtls,
                is_soldout: modifierQty[0].is_soldout,
                is_emenu_disable: modifierQty[0].is_emenu_disable,
                is_avl_limit_check: modifierQty[0].is_avl_limit_check,
                bal_qty: modifierQty[0].bal_qty,
              });
            } else {
              itemmaster_menutypedtls.push({ ...typeDtls });
            }
          }
        }

        stockedCheckedItem.push({ ...itemMaster, itemmaster_menutypedtls });
      }
    }

    const filterNoItemMaster = newResult.filter(
      (item) =>
        !item.itemmaster_menutypedtls ||
        item.itemmaster_menutypedtls.length <= 0
    );

    let intersectItems = _.intersectionBy(
      intersectCategory,
      [...filterNoItemMaster, ...stockedCheckedItem],
      (obj) => obj.item_category || obj.item_no
    ).filter((item) => item.is_active); // temp disable

    intersectItems = Object.values(
      _.merge(
        _.keyBy(intersectCategory, "item_category"),
        _.keyBy([...filterNoItemMaster, ...stockedCheckedItem], "item_no")
      )
    )
      .filter(
        (item) => item.is_active && categoryCodes.includes(item.category_code)
      )
      .filter((item) => item.is_emenu_disable === "N");

    const brand = process.env.REACT_APP_MODE;

    let responseProductByCategory = [];

    if (stateProductByCategory?.length > 0) {
      responseProductByCategory = stateProductByCategory;
    } else {
      const responseSupabaseProduct = await getProductsByCategory(
        brand,
        currentCategory
      );

      responseProductByCategory = responseSupabaseProduct?.data;

      setStateProductByCategory(responseSupabaseProduct?.data);
    }

    const topPicks = supabaseProducts || [];
    const productByCategory = responseProductByCategory;

    // if menu item_no is in topPicksId, the then set is_top_pick to true
    intersectItems = intersectItems.map((item) => {
      if (topPicks.includes(item.item_no)) {
        return { ...item, is_top_pick: true };
      }
      return { ...item };
    });

    const sortAlphabetically = intersectItems.sort((a, b) =>
      a.item_desc.localeCompare(b.item_desc)
    );
    const categoryMenus = sortAlphabetically.filter(
      (item) => item.category_code === currentCategory
    );

    const newCategoryMenus = [];

    categoryMenus?.forEach((record) => {
      const findItem = productByCategory?.find(
        (product) => product?.name === record?.item_name
      );

      let newItem = {
        ...record,
      };

      if (findItem) {
        newItem = {
          ...newItem,
          dummy_price: findItem?.dummy_price,
          supabase_item_desc: findItem?.description,
        };
      }

      newCategoryMenus.push(newItem);
    });

    const sortByCategory = newCategoryMenus.filter(
      (item) =>
        item.start_time <= moment().format("HH:mm:ss") &&
        item.end_time > moment().format("HH:mm:ss")
    );

    return sortByCategory?.length > 0;
  };

  const checkingEmptyCategory = async (allCategories, categories) => {
    const categoriesPromiseMap = categories.map(async (record) => {
      const result = await itemsListAndCategory(allCategories, record);

      return {
        result,
        name: record,
      };
    });

    const formatCategories = await Promise.all(categoriesPromiseMap);

    return formatCategories
      ?.filter((record) => record?.result)
      ?.map((record) => record?.name);
  };

  return {
    checkingEmptyCategory,
  };
};

export default useCategory;
