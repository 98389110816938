import { apiCall } from "../../lib/supabase";

export const getOutletByName = async (outletName) => {
  const response = await apiCall
    .from("outlet")
    .select("*")
    .ilike("name", `%${outletName}%`)
    .limit(1);

  return response;
};
