import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = ({ title, description, onCancel, onConfirm, open }) => {
  return (
    <Modal show={open} onHide={onCancel} centered className="customize-content">
      <Modal.Header closeButton className="modal-border" closeVariant="white">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button
          className="fill-cancel-btn w-100 fill-green-btn cart-btn text-uppercase fw-bold"
          onClick={onConfirm}>
          Confirm
        </Button>
        <Button
          className="w-100 text-uppercase fw-bold cancel-btn"
          onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
