import { apiCall } from "../../lib/supabase";

export const getProducts = async (brand) => {
  const response = await apiCall
    .from("product")
    .select("*, brand!inner(id, name)")
    .eq("brand.name", brand);

  return response;
};

export const getProductByIds = async (productIds) => {
  const response = await apiCall
    .from("product")
    .select("*")
    .in("id", productIds);

  return response;
};

export const getProductsByCategory = async (brand, category) => {
  const response = await apiCall
    .from("product")
    .select("*, brand!inner(id, name), category!inner(id, name)")
    .eq("brand.name", brand)
    .eq("category.name", category);

  return response;
};

export const getProductByPOSItemNo = async (brand, posItemNo) => {
  const response = await apiCall
    .from("product")
    .select("*, brand!inner(id, name)")
    .eq("brand.name", brand)
    .eq("pos_item_no", posItemNo);

  return response;
};