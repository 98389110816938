import React, {
  Fragment,
  createRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Header from "../../component/header";
import CarouselBanner from "./CarouselBanner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineShoppingCart } from "react-icons/md";
import { SlBag } from "react-icons/sl";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Button, Card, Spinner } from "react-bootstrap";
import {
  storeMenuButton,
  storeMenuItems,
  storeStockList,
} from "../../services/tableQRServices";
import _, { isEmpty } from "lodash";
import MenuItems from "./MenuItems";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCategories } from "../../services/supabase/category";
import MenuDetail from "./MenuDetail";
import { getProducts } from "../../services/supabase/product";
import { CustomizeModal } from "./CustomizeModal";
import "./css/home.css";
import UpsellingModal from "../../component/Upselling/UpsellingModal";
import getUpsellingItems from "../../utils/getUpsellingitems";
import useBrandConfig from "../../hooks/useBrandConfig";
import WelcomeBanner from "../../component/WelcomeBanner/WelcomeBanner";
import useCategory from "../../hooks/useCategory";
import {
  setPosFullItemList,
  setPosMenuButton,
  setPosStoreStockList,
} from "../../redux/posSlice";
import usePromotion from "../../hooks/usePromotion";

function Home() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigationData = location.state;

  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const [isBottomOpen, setIsBottomOpen] = useState(true);
  const [enableOverlay, setEnableOverlay] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [time, setTime] = useState("12:34pm");
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [searchedMenuItems, setSearchedMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedMenuItem] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showUpsellingModal, setShowUpsellingModal] = useState(false);
  const [upsellingItems, setUpsellingItems] = useState([]);
  const [productsSupabase, setProductsSupabase] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [currentSubCategory, setCurrentSubCategory] = useState("");

  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showModalData, setShowModalData] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [originalPOSCategories, setOriginalPOSCategories] = useState([]);
  const [allShowCategories, setAllShowCategories] = useState([]);

  const scrollRefs = useRef([]);
  const navRefs = useRef([]);

  const { config } = useBrandConfig();
  const { checkingEmptyCategory } = useCategory();
  const { posStoreStockList, posMenuButton, posFullItemList } = useSelector(
    (state) => state.pos
  );
  const { handleConvertPromotionItem } = usePromotion();

  const cartItems = useSelector((state) => state.cart.cart);
  // get items from the cart and then read the qty of each item
  // sum the items to get cart items count each time an item is added to the cart
  const cartItemsCount = cartItems.reduce((acc, item) => acc + item.qty, 0);

  useEffect(() => {
    setIsBottomOpen(false);
    if (config?.serviceMode === "DineInOnly") {
      // setIsBottomOpen(false);
      localStorage.setItem("selectiDineOption", "dinein");
    } else if (config?.serviceMode === "TakeAwayOnly") {
      // setIsBottomOpen(false);
      localStorage.setItem("selectiDineOption", "takeaway");
    }

    if (config?.isDineOption) {
      let getStorageOption = localStorage.getItem("selectiDineOption");
      if (getStorageOption === "" || getStorageOption === null) {
        setIsBottomOpen(true);
      }
    }
  }, [config]);

  const subTotal = useSelector((state) => state.cart.total);
  const dispatcher = useDispatch();

  const fetchSupabaseCategoriesOriginal = async () => {
    setIsLoading(true);

    const resp = await getCategories(process.env.REACT_APP_MODE);
    const respMainCategory = await storeMenuButton();

    setOriginalCategories(resp.data);
    setOriginalPOSCategories(respMainCategory?.data?.data?.[0]?.output);
  };

  const itemsListAndCategory = () => {
    setIsLoading(true);
    const resultParse = JSON.parse(posStoreStockList);

    const newResultParse = resultParse?.filter(
      (record) => record?.is_emenu_disable !== "Y"
    );
    // let categoryCodes = [];
    // for (const category of resultParse.filter(
    //   (category) => category.avl_type === "C"
    // )) {
    //   categoryCodes.push(category.item_category);
    // }
    let resultItemsParsed = newResultParse.filter(
      (category) => category.avl_type === "I"
    );
    const categoryCodes = allShowCategories;
    menuButtonFunction(categoryCodes, resultItemsParsed, resultParse);
  };

  const menuButtonFunction = (
    categoryCodes,
    resultItemsParsed,
    resultParse
  ) => {
    let newCategoryCodes = [];

    categoryCodes?.forEach((record) => {
      const findCategory = resultParse?.find(
        (cat) => cat?.item_category === record
      );

      if (
        findCategory &&
        findCategory?.is_emenu_disable !== "Y" &&
        findCategory?.is_soldout !== "Y"
      ) {
        newCategoryCodes.push(record);
      } else if (!findCategory) {
        newCategoryCodes.push(record);
      }
    });

    const categoryMenuButton = posMenuButton;

    let categoryMainMenu = categoryMenuButton.filter(
      (menuItem) => menuItem.category && menuItem.category.length > 0
      // (menuItem) => menuItem.root_category_code === "MAIN"
      // filter menuItem.items length > 0
      // (menuItem) => menuItem.items.length > 0
    );
    let categoryMainMenuButton = [];
    for (const catItems of categoryMainMenu) {
      categoryMainMenuButton.push(...catItems.category);
    }
    if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
      let categoriesIndex = categoryMainMenuButton;
      // categoryMainMenuButton = categoryMainMenuButton[0];
      // let categoriesIndex = categoryMainMenuButton.category;
      // ? Sort on sequences and filter on start and end time
      categoriesIndex = categoriesIndex
        .sort((a, b) => a.course_seq - b.course_seq)
        .filter(
          (category) =>
            category.start_time <= moment().format("HH:mm:ss") &&
            category.end_time > moment().format("HH:mm:ss")
        );

      newCategoryCodes = categoryMenuButton
        .filter((category) => category.items !== "")
        .map((category) => {
          return category.root_category_code;
        });
      const categoregoryFromApi = categoryMenuButton.map((category) => {
        return category.root_category_code;
      });

      // make sure categories codes has items on it
      newCategoryCodes = _.intersection(newCategoryCodes, categoregoryFromApi);
      // for (const category of categoriesIndex) {
      //   categoryCodes.push(category.category_code);
      // }
      let menuItemsArray = [];
      for (const category of newCategoryCodes) {
        const catItem = categoryMenuButton.filter(
          (catItems) => catItems.root_category_code === category
        );
        if (
          catItem &&
          catItem.length > 0 &&
          catItem[0].items &&
          catItem[0].items.length > 0
        ) {
          menuItemsArray.push(...catItem[0].items);
        }
      }

      // ? Intersect items from stock and button api
      let intersectCategory = _.intersectionBy(
        resultItemsParsed,
        menuItemsArray,
        (obj) => obj.item_category || obj.item_no
      );
      // ? Intersect items from with intersected items and button api items
      intersectCategory = Object.values(
        _.merge(
          _.keyBy(intersectCategory, "item_category"),
          _.keyBy(menuItemsArray, "item_no")
        )
      ).filter(
        (item) =>
          item.is_emenu_disable === "N" &&
          item.start_time <= moment().format("HH:mm:ss") &&
          item.end_time > moment().format("HH:mm:ss")
      );
      setMenuCategories(newCategoryCodes);
      menuItemsFunction(intersectCategory, newCategoryCodes, resultParse);
    }
  };

  const menuItemsFunction = async (
    intersectCategory,
    categoryCodes,
    resultParse
  ) => {
    const result = posFullItemList;

    const filterItemMaster = result.filter(
      (item) =>
        item.itemmaster_menutypedtls && item.itemmaster_menutypedtls.length > 0
    );

    const stockedCheckedItem = [];
    if (filterItemMaster && filterItemMaster.length > 0) {
      for (const itemMaster of filterItemMaster) {
        let itemmaster_menutypedtls = [];
        for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
          const modifierQty = resultParse.filter(
            (itemCategory) => itemCategory.item_no === typeDtls.citem_no
          );

          if (modifierQty && modifierQty.length > 0) {
            itemmaster_menutypedtls.push({
              ...typeDtls,
              is_soldout: modifierQty[0].is_soldout,
              is_emenu_disable: modifierQty[0].is_emenu_disable,
              is_avl_limit_check: modifierQty[0].is_avl_limit_check,
              bal_qty: modifierQty[0].bal_qty,
            });
          } else {
            itemmaster_menutypedtls.push({ ...typeDtls });
          }
        }

        stockedCheckedItem.push({ ...itemMaster, itemmaster_menutypedtls });
      }
    }
    const filterNoItemMaster = result.filter(
      (item) =>
        !item.itemmaster_menutypedtls ||
        item.itemmaster_menutypedtls.length <= 0
    );

    let intersectItems = _.intersectionBy(
      intersectCategory,
      [...filterNoItemMaster, ...stockedCheckedItem],
      (obj) => obj.item_category || obj.item_no
    ).filter((item) => item.is_active);

    intersectItems = Object.values(
      _.merge(
        _.keyBy(intersectCategory, "item_category"),
        _.keyBy([...filterNoItemMaster, ...stockedCheckedItem], "item_no")
      )
    )
      .filter(
        (item) => item.is_active && categoryCodes.includes(item.category_code)
      )
      .filter((item) => item.is_emenu_disable === "N");

    // if menu item_no is in topPicksId, the then set is_top_pick to true
    const brand = process.env.REACT_APP_MODE;
    const topPicks = await fetchSupabaseProducts(brand);
    intersectItems = intersectItems.map((item) => {
      if (topPicks.includes(item.item_no)) {
        return { ...item, is_top_pick: true };
      }
      return { ...item };
    });

    const sortAlphabetically = intersectItems.sort((a, b) =>
      a.item_desc.localeCompare(b.item_desc)
    );
    const sortByCategory = sortAlphabetically
      .sort(
        (a, b) =>
          categoryCodes.indexOf(a.category_code) -
          categoryCodes.indexOf(b.category_code)
      )
      .filter(
        (item) =>
          item.start_time <= moment().format("HH:mm:ss") &&
          item.end_time > moment().format("HH:mm:ss")
      );

    const categoryCodesPromise = allShowCategories.map(async (cat) => {
      let formatSubCategory = [];

      const findSubCategory = originalPOSCategories?.find(
        (record) => record?.root_category_code === cat
      );

      if (findSubCategory && findSubCategory?.category) {
        findSubCategory?.category?.forEach((record) => {
          if (
            allCategories?.find((cat) => cat?.name === record?.category_code)
          ) {
            const findCategory = allCategories?.find(
              (cat) => cat?.name === record?.category_code
            );
            formatSubCategory.push(findCategory);
          }
        });

        const formatCategory = await checkingEmptyCategory(
          allCategories,
          formatSubCategory?.map((record) => record?.name)
        );

        return {
          categoryCode: findSubCategory?.root_category_code,
          subList: formatCategory,
        };
      }
    });

    const finalSubCategory = await Promise.all(categoryCodesPromise);

    setSubCategory(finalSubCategory?.filter((record) => record));
    setMenuItems(sortByCategory);
    setIsLoading(false);
  };

  const fetchSupabaseCategories = async () => {
    const filterCategory = originalCategories?.filter(
      (record) => record?.is_hidden !== true && record?.is_hidden !== "Y"
    );

    let formatAllCategories = [];

    (
      originalPOSCategories?.find(
        (record) => record?.root_category_code === "MAIN"
      )?.category || []
    )
      ?.filter(
        (category) =>
          category.start_time <= moment().format("HH:mm:ss") &&
          category.end_time > moment().format("HH:mm:ss")
      )
      ?.forEach((record) => {
        const findCategory = filterCategory?.find(
          (cat) => cat?.name === record?.category_code
        );

        if (findCategory) {
          const findCategory = filterCategory?.find(
            (cat) => cat?.name === record?.category_code
          );
          formatAllCategories.push(findCategory);
        }
      });

    formatAllCategories = formatAllCategories.sort(
      (a, b) => a?.sequence - b?.sequence
    );

    const formatSubCategory = [];

    formatAllCategories
      ?.map((record) => record?.name)
      .forEach((cat) => {
        const findSubCategory = originalPOSCategories?.find(
          (record) => record?.root_category_code === cat
        );

        if (
          findSubCategory &&
          !findSubCategory?.category &&
          findSubCategory?.items
        ) {
          const findCategory = filterCategory?.find(
            (cat) => cat?.name === findSubCategory?.root_category_code
          );

          if (findCategory) {
            formatSubCategory.push(findCategory);
          }
        }
      });

    const formatCategory = await checkingEmptyCategory(
      filterCategory,
      formatSubCategory?.map((record) => record?.name)
    );

    const filterAllCategories = formatAllCategories?.filter(
      (record) =>
        !formatSubCategory
          ?.map((record) => record?.name)
          ?.includes(record?.name)
    );
    const filterFormatCategories = [];

    formatCategory
      ?.filter((record) => record)
      ?.forEach((record) => {
        const findCategory = filterCategory?.find(
          (cat) => cat?.name === record
        );

        if (findCategory) {
          filterFormatCategories.push(findCategory);
        }
      });

    setMainCategories(
      formatAllCategories?.sort((a, b) => a?.sequence - b?.sequence)
    );
    setAllCategories(filterCategory?.sort((a, b) => a?.sequence - b?.sequence));
    setAllShowCategories(
      [...filterFormatCategories, ...filterAllCategories]
        ?.sort((a, b) => a?.sequence - b?.sequence)
        ?.map((record) => record?.name)
    );
    setCategories(
      [...filterAllCategories, ...filterFormatCategories]
        ?.sort((a, b) => a?.sequence - b?.sequence)
        ?.map((item) => ({
          name: item.name.toUpperCase(),
          imageUrl: item.header_image_url,
        }))
    );
  };

  useEffect(() => {
    fetchSupabaseCategoriesOriginal();
    fetchSupabaseProducts(process.env.REACT_APP_MODE);
  }, []);

  useEffect(() => {
    if (
      originalCategories?.length > 0 &&
      originalPOSCategories?.length > 0 &&
      posStoreStockList &&
      JSON.parse(posStoreStockList)?.length > 0 &&
      posMenuButton?.length > 0 &&
      posFullItemList?.length > 0
    ) {
      fetchSupabaseCategories();
    }
  }, [
    originalCategories?.length,
    originalPOSCategories?.length,
    posFullItemList,
    posStoreStockList,
    posMenuButton,
  ]);

  useEffect(() => {
    if (
      mainCategories?.length > 0 &&
      originalCategories?.length > 0 &&
      originalPOSCategories?.length > 0 &&
      allCategories?.length > 0 &&
      posStoreStockList &&
      JSON.parse(posStoreStockList)?.length > 0 &&
      posMenuButton?.length > 0 &&
      posFullItemList?.length > 0
    ) {
      itemsListAndCategory();
    }
  }, [
    allCategories?.length,
    mainCategories?.length,
    originalCategories?.length,
    originalPOSCategories?.length,
    posStoreStockList,
    posMenuButton,
    posFullItemList,
  ]);

  // fetch products from supabase
  const fetchSupabaseProducts = async (brand) => {
    setIsLoading(true);
    const resp = await getProducts(brand);
    if (resp.data.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp.data.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks.map((item) => item.pos_item_no);
      setProductsSupabase(resp.data);
      return topPicksIds;
    }
  };

  let menuItem, menuDish;
  menuItem = menuCategories;
  menuDish = searchedMenuItems.length !== 0 ? searchedMenuItems : menuItems;
  scrollRefs.current = [...Array(menuItem.length).keys()].map(
    (_, i) => scrollRefs.current[i] ?? createRef()
  );

  navRefs.current = [...Array(menuItem.length).keys()].map(
    (_, i) => navRefs.current[i] ?? createRef()
  );

  const scrollToElement = (index) => {
    scrollRefs?.current?.[index]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const scrollToSubCatElement = (catReff) => {
    const category = document.getElementById(catReff);
    if (category) {
      category.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const placeOrder = async (dishDetail) => {
    if (dishDetail.itemmaster_menutype_grpdtls === "") {
      dispatcher(
        addToCart(
          handleConvertPromotionItem({
            id: dishDetail.item_no,
            addOnValues: [],
            item: dishDetail,
            qty: 1,
            price: dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
          })
        )
      );
      toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
        dishDetail.category_code,
        dishDetail.item_category
      );
      if (hasUpsellingItems) {
        setUpsellingItems(upsellItems);
        setShowUpsellingModal(true);
      }
    } else {
      const customDetails = {
        item: dishDetail,
      };

      if (config?.usingCustomizePage) {
        navigate("/customize-dish", {
          state: customDetails,
        });
      } else {
        setShowModalData(customDetails);
        setShowCustomizeModal(true);
      }
    }
  };
  const toggleBottom = () => {
    if (
      localStorage.getItem("selectiDineOption") === "dinein" ||
      localStorage.getItem("selectiDineOption") === "takeaway"
    ) {
      setIsBottomOpen((prev) => !prev);
    }
  };

  const openDiningOption = () => {
    setIsBottomOpen(true);
  };

  const selectDining = (selectionOption) => {
    localStorage.setItem("selectiDineOption", selectionOption);
    // set current cart to dine in
    setIsBottomOpen(false);
  };
  const searchFunction = (values) => {
    if (values && values.length > 0) {
      setSearchedMenuItems(
        menuItems.filter(
          (item) =>
            item.item_name.includes(values) ||
            item.item_name.toLowerCase().includes(values.toLowerCase()) ||
            item.item_name.toUpperCase().includes(values.toUpperCase())
        )
      );
    } else {
      // itemsListAndCategory();
      setSearchedMenuItems(menuItems);
    }
  };
  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
  };

  const [initialized, setInitialized] = useState(false);
  const menuRef = useRef();

  let pendingAction;
  const calculateScrollFn = () => {
    if (pendingAction) {
      clearTimeout(pendingAction);
      pendingAction = false;
    }
    pendingAction = setTimeout(() => {
      const activeMenuLi = document.querySelector(".spyItemClass.active");
      if (!activeMenuLi) {
        return;
      }
      const menuContainer = activeMenuLi?.parentNode;

      const elementRect = activeMenuLi?.getBoundingClientRect();
      const containerRect = menuContainer?.getBoundingClientRect();

      let scrollGap;
      if (elementRect.top < containerRect.top) {
        scrollGap = elementRect.top - containerRect.top;
      }
      if (elementRect.bottom > containerRect.bottom) {
        scrollGap = elementRect.bottom - containerRect.bottom;
      }
      if (scrollGap) {
        menuContainer.scrollBy(0, scrollGap);
      }
    }, 50);
  };

  function initScrollListener() {
    if (initialized) {
      return;
    }
    if (!menuRef || !menuRef.current) {
      return;
    }
    menuRef.current.removeEventListener("scroll", calculateScrollFn);
    menuRef.current.addEventListener("scroll", calculateScrollFn);
    setInitialized(true);
  }
  const toggleDetailModal = (data = {}) => {
    setSelectedMenuItem(data);
    if (showDetailModal) {
      setShowDetailModal(false);
    } else {
      setShowDetailModal(true);
    }
  };
  const getCategoryImage = useCallback(
    (categoryCode) => {
      const matchedData = categories.find((item) => item.name === categoryCode);
      if (matchedData) {
        return matchedData.imageUrl;
      }
      return null;
    },
    [categories]
  );

  const handleCustomModalSuccessSubmit = async (submittedItem) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      submittedItem.category_code,
      submittedItem.item_category
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };

  const isCategoryHasDishes = useCallback(
    (category) => {
      const hasSubCategories = subCategory.some(
        (subCat) =>
          subCat.categoryCode === category && subCat.subList.length > 0
      );

      const hasMenuDishes = menuDish.some(
        (menuDishes) => menuDishes.category_code === category
      );

      return hasSubCategories || hasMenuDishes;
    },
    [subCategory, menuDish]
  );

  const handleGetStockList = async () => {
    const res = await storeStockList();

    const result = res?.data?.data[0]?.output;

    dispatch(setPosStoreStockList(result));
  };

  const handleGetMenuButton = async () => {
    const res = await storeMenuButton();

    const categoryMenuButton = res?.data?.data[0]?.output;

    dispatch(setPosMenuButton(categoryMenuButton));
  };

  const handleGetMenuItems = async () => {
    const res = await storeMenuItems();

    const result = res?.data?.data[0]?.output;

    dispatch(setPosFullItemList(result));
  };

  useLayoutEffect(() => {
    if (
      !posStoreStockList &&
      !posMenuButton?.length &&
      !posFullItemList?.length
    ) {
      handleGetStockList();
      handleGetMenuButton();
      handleGetMenuItems();
    }
  }, [posFullItemList, posMenuButton, posStoreStockList]);

  const clearState = () => {
    navigate(location.pathname, { replace: true });
  };

  const checkUpselling = async (categoryCode, itemCategory) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      categoryCode,
      itemCategory
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };

  useEffect(() => {
    if (!isEmpty(navigationData)) {
      const {
        item: { category_code, item_category },
      } = navigationData;
      checkUpselling(category_code, item_category);
    }
  }, [navigationData]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header
            title="home"
            showSearchBar={showSearchBar}
            handleSearchClick={handleSearchClick}
            searchFunction={searchFunction}
            openDiningOption={openDiningOption}
            navRefs={navRefs}
            menuItem={menuItem}
            menuDish={menuDish}
            scrollTo={scrollToElement}
            showMenu
            allShowCategories={allShowCategories.filter((catItem) =>
              isCategoryHasDishes(catItem)
            )}
          />
          <div className="main-div home-div w-100" ref={menuRef}>
            {config?.home_banner_enabled && <CarouselBanner />}
            <div className="top-border d-flex flex-column">
              {isLoading ? (
                <Spinner animation="border material-icons align-self-center position-absolute top-50" />
              ) : (
                <>
                  {menuItem && menuItem.length > 0 ? (
                    <>
                      <div id="menuitems" className="menuitems w-100 pt-2">
                        {allShowCategories
                          .filter((catItem) => isCategoryHasDishes(catItem))
                          .map((category, index) => {
                            const findSubCategory = subCategory.find(
                              (subCat) => subCat.categoryCode === category
                            );

                            if (findSubCategory) {
                              return (
                                <>
                                  <div
                                    key={`category-${index}`}
                                    className="category-container">
                                    <div
                                      id={category}
                                      ref={scrollRefs?.current?.[index]}
                                      className="category-scroll"
                                    />
                                    {isCategoryHasDishes(category) && (
                                      <Card
                                        style={{
                                          marginLeft: "16px",
                                          marginRight: "16px",
                                          marginBottom: "10px",
                                          marginTop: "10px",
                                          position: "sticky",
                                          top: "7px",
                                          zIndex: 1,
                                          border: "none",
                                        }}>
                                        {getCategoryImage(category) !== null ? (
                                          <Card.Img
                                            style={{ objectFit: "cover" }}
                                            height={40}
                                            src={getCategoryImage(category)}
                                          />
                                        ) : (
                                          <Card.Body
                                            style={{
                                              padding: "6px 4px 2px 4px",
                                            }}>
                                            <Card.Title
                                              style={{
                                                fontWeight: "bold",
                                                color: "white",
                                                height: "32px",
                                                marginBottom: "0px",
                                              }}>
                                              {category}
                                            </Card.Title>
                                          </Card.Body>
                                        )}

                                        {findSubCategory?.subList?.length >
                                          0 && (
                                          <div className="d-flex align-items-center gap-2 mt-2 mb-2 overflow-auto">
                                            {findSubCategory?.subList?.map(
                                              (record) => (
                                                <button
                                                  key={record}
                                                  className={`sub-category-item ${
                                                    currentSubCategory ===
                                                    record
                                                      ? "active-sub-category-item"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    setCurrentSubCategory(
                                                      record
                                                    );
                                                    scrollToSubCatElement(
                                                      `subCategory-${record}`
                                                    );
                                                  }}>
                                                  {/* <a 
                                              href={`#subCategory-${record}`}
                                              > */}
                                                  {record}
                                                  {/* </a> */}
                                                </button>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </Card>
                                    )}

                                    <div
                                      className={"d-flex flex-wrap"}
                                      style={{
                                        justifyContent: "center",
                                        gap: 16,
                                        padding: "0 16px",
                                      }}>
                                      {findSubCategory?.subList?.map(
                                        (cat, index) => {
                                          const newMenuDishes = menuDish.filter(
                                            (menuDishes) =>
                                              menuDishes.category_code === cat
                                          );

                                          if (!newMenuDishes?.length)
                                            return null;

                                          return (
                                            <Fragment key={`cat-${cat}`}>
                                              {newMenuDishes.map(
                                                (menuDishes, index) => {
                                                  return (
                                                    <MenuItems
                                                      key={`menuItem-${index}`}
                                                      menuDishes={menuDishes}
                                                      placeOrder={placeOrder}
                                                      onDetailOpen={
                                                        toggleDetailModal
                                                      }
                                                      productsSupabase={
                                                        productsSupabase
                                                      }
                                                      category={cat}
                                                    />
                                                  );
                                                }
                                              )}
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            }

                            const newMenuDishes = menuDish.filter(
                              (menuDishes) =>
                                menuDishes.category_code === category
                            );

                            if (!newMenuDishes?.length) return null;

                            return (
                              <div
                                key={`category-${index}`}
                                className="category-container">
                                <div
                                  id={category}
                                  ref={scrollRefs?.current?.[index]}
                                  className="category-scroll"
                                />
                                <Card
                                  style={{
                                    marginLeft: "16px",
                                    marginRight: "16px",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    position: "sticky",
                                    top: "7px",
                                    zIndex: 1,
                                  }}>
                                  {getCategoryImage(category) !== null ? (
                                    <Card.Img
                                      // variant="top"
                                      style={{ objectFit: "cover" }}
                                      height={40}
                                      // src="https://img.freepik.com/free-photo/top-view-table-full-delicious-food-composition_23-2149141353.jpg?w=2000"
                                      src={getCategoryImage(category)}
                                    />
                                  ) : (
                                    <Card.Body
                                      style={{
                                        padding: "6px 4px 2px 4px",
                                      }}>
                                      <Card.Title
                                        style={{
                                          fontWeight: "bold",
                                          color: "white",
                                          height: "32px",
                                          marginBottom: "0px",
                                        }}>
                                        {category}
                                      </Card.Title>
                                    </Card.Body>
                                  )}
                                </Card>
                                <div
                                  className={"d-flex flex-wrap"}
                                  style={{
                                    justifyContent: "center",
                                    gap: 16,
                                    padding: "0 16px",
                                  }}>
                                  {newMenuDishes.map((menuDishes, index) => {
                                    return (
                                      <MenuItems
                                        key={`menuItem-${index}`}
                                        menuDishes={menuDishes}
                                        placeOrder={placeOrder}
                                        onDetailOpen={toggleDetailModal}
                                        productsSupabase={productsSupabase}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <div>
                      <img
                        src={`/assets/commonImages/${config?.search}.png`}
                        alt=""
                        className="mt-5"
                      />
                      <div className="d-grid">
                        <label className="no-result-text black-text">
                          No Result
                        </label>
                        <label className="no-result-desc text-black">
                          Sorry we couldn't find any result, try another
                          keyword.
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="home-bottom-div">
                    <Link to="/cart">
                      <button className="viewcart-btn w-60 fill-btn mb-2 me-4 ms-4 pulsing-button">
                        <>
                          <div className="d-flex justify-content-space-evenly pt-1 flex-wrap">
                            <div className="col">
                              <MdOutlineShoppingCart className="shopping-cart-icon" />
                            </div>
                            <div className="col fw-bold text-start">
                              Items: {cartItemsCount}
                            </div>
                            <div className="cart-price col">
                              $ {Number(subTotal).toFixed(2)}
                            </div>
                          </div>
                        </>
                      </button>
                    </Link>
                  </div>
                </>
              )}
            </div>

            <Drawer
              open={isBottomOpen}
              onClose={toggleBottom}
              direction="bottom"
              size={300}
              enableOverlay={enableOverlay}>
              <div>
                <label className="d-flex pt-3 ps-3 select-option-text text-uppercase">
                  Select your dining experience
                </label>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <span className="material-icons pt-3"> restaurant </span>
                    </div>
                    <label className="option-text mt-3 ms-3 text-uppercase">
                      Dine in
                    </label>
                    <Button
                      className="fill-btn cart-btn dining-btn mt-2 position-absolute end-0 me-4 mt-2 text-uppercase"
                      type="submit"
                      onClick={() => selectDining("dinein")}>
                      Select
                    </Button>
                  </div>
                </div>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <SlBag className="material-icons mt-3 font-18" />
                    </div>
                    <label className="option-text mt-3 ms-3 text-uppercase">
                      Takeaway
                    </label>
                    <Button
                      className="fill-btn cart-btn dining-btn mt-2 position-absolute end-0 me-4 mt-2 text-uppercase"
                      type="submit"
                      onClick={async () => {
                        localStorage.setItem("selectiDineOption", "takeaway");
                        setIsBottomOpen(false);
                      }}>
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>

      {showDetailModal ? (
        <MenuDetail
          show={showDetailModal}
          onHide={toggleDetailModal}
          data={selectedItem}
          placeOrder={placeOrder}
          productsSupabase={productsSupabase}
        />
      ) : null}
      {showCustomizeModal ? (
        <CustomizeModal
          showModal={showCustomizeModal}
          setShowModal={setShowCustomizeModal}
          showModalData={showModalData}
          onSuccessSubmit={handleCustomModalSuccessSubmit}
        />
      ) : null}
      {showUpsellingModal ? (
        <UpsellingModal
          show={showUpsellingModal}
          handleClose={() => setShowUpsellingModal(false)}
          recommendationItems={upsellingItems}
          clearUpsellingState={clearState}
        />
      ) : null}
      <WelcomeBanner />
    </>
  );
}
export default Home;
