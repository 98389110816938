import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../component/header";
import { BsPlusLg, BsDashLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BiPencil } from "react-icons/bi";
import { Button } from "react-bootstrap";
import { BsFillTrash3Fill } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import { STORAGEKEY } from "../../constants";
import { PostSaleCreate } from "../../services/tableQRServices";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  placeOrderSuccess,
  addSalesNo,
  deleteCartItem,
  setTotal,
} from "../../redux/cartSlice";
import Recommendation from "./recommendation";
import _ from "lodash";
import { ConfirmationModal } from "./confirmationModal";
import { Spinner } from "react-bootstrap";
import ConfirmModal from "./ConfirmModal";
import useBrandConfig from "../../hooks/useBrandConfig";
import CartItem from "./CartItem";
import { getProducts } from "../../services/supabase/product";
import { sendPosLog } from "../../services/supabase/posLog";
import useSessionTimeout from "../../hooks/useSessionTimeout";
import usePromotion from "../../hooks/usePromotion";
import { CustomizeModal } from "../home/CustomizeModal";

function Cart() {
  const { config } = useBrandConfig();
  const {
    handleConvertPromotionTotal,
    handleConvertPromotionCart,
    handleConvertPromotionCartItem,
    handleConvertPromotionModifierItem,
    handleGroupCart,
  } = usePromotion();
  const sessionTimeout = useSessionTimeout();
  const { state } = useLocation();
  const cartData = useSelector((state) => state.cart.cart);
  const total = useSelector((state) => state.cart.total);
  const salesNo = useSelector((state) => state.cart.sales_no);
  const dineOption = localStorage.getItem("selectiDineOption");
  const orderTime = localStorage.getItem("orderTime");
  const [serviceTaxPer, setServiceTaxPer] = useState(0);
  const [serviceTax, setServiceTax] = useState(0);
  let gstInclusive = config?.gstInclusive;
  const [isErrorShow, setIsErrorShow] = useState(false);
  const [taxValue] = useState(8);
  const [showModal, setShowModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [GSTTaxValue, setGSTTaxValue] = useState(0);
  const [GSTInclusiveTaxValue, setGSTInclusiveTaxValue] = useState(0);
  const [showGSTTaxValue, setShowGSTTaxValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatcher = useDispatch();

  const formatPromotionCartData = handleGroupCart(
    handleConvertPromotionCart(cartData)
  );

  const cartItemsCount = formatPromotionCartData.reduce(
    (acc, item) => acc + item.qty,
    0
  );

  const [isLoading, setIsLoading] = useState(false);
  const [showPaylaterConfirmModal, setShowPaylaterConfirmModal] =
    useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [productsSupabase, setProductsSupabase] = useState([]);

  const [showModalOpen, setShowModalOpen] = useState(false);
  const [showModalData, setShowModalData] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  useEffect(() => {
    if (config?.enableServiceCharge && dineOption === "dinein") {
      setServiceTaxPer(0.1);
    }
  }, [config, dineOption]);

  useEffect(() => {
    if (config?.gstInclusive) {
      setGSTInclusiveTaxValue(((total / 108) * 8).toFixed(2));
    }

    if (config?.enableGST) {
      setGSTTaxValue(((total + total * serviceTaxPer) * 0.08).toFixed(2));
      setShowGSTTaxValue(((total + total * serviceTaxPer) * 0.08).toFixed(2));
    }

    if (config?.enableServiceCharge) {
      setServiceTax((total * serviceTaxPer).toFixed(2));
    }

    cartData.forEach((cartItem) => {
      gstInclusive = cartItem.item.is_absorbtax ? true : false;
    });
  }, [config, total, serviceTaxPer]);
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  const incrementCounter = (uuid) => {
    const itemInCart = formatPromotionCartData.find(
      (item) => item.uuid === uuid
    );

    dispatcher(
      incrementQuantity(handleConvertPromotionModifierItem(itemInCart))
    );
  };

  const decrementCounter = (uuid) => {
    const itemInCart = formatPromotionCartData.find(
      (item) => item.uuid === uuid
    );
    const firstUuid = itemInCart?.uuidList?.[0];
    const findOriginalItem = cartData.find((item) => item.uuid === firstUuid);

    dispatcher(
      decrementQuantity(
        handleConvertPromotionModifierItem(findOriginalItem, "minus")
      )
    );
  };
  const placeOrder = (id) => {
    const item = formatPromotionCartData.find((item) => item.uuid === id);
    const customDetails = {
      uuid: item.uuid,
      item: item.item,
      addOnValues: item.addOnValues,
      qty: item.qty,
      remarks: item.remarks,
    };

    if (config?.usingCustomizePage) {
      navigate("/customize-dish", {
        state: customDetails,
      });
    } else {
      setShowModalData(customDetails);
      setShowModalOpen(true);
    }
  };
  const formatDate = (date) => {
    const result = date.toLocaleString("en-SG", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return result;
  };

  const newTotal = useMemo(() => {
    let value = Number(total);

    if (config?.enableServiceCharge) {
      value += Number(serviceTax);
    }

    if (config?.enableGST && !config?.gstInclusive) {
      value += Number(GSTTaxValue);
    }

    return value;
  }, [
    GSTTaxValue,
    config?.enableGST,
    config?.enableServiceCharge,
    config?.gstInclusive,
    serviceTax,
    total,
  ]);

  const paymentpage = async (usingOnlineLink, type) => {
    setIsLoading(true);
    setShowModal(false);

    let salesDetails = [];
    let i = 1;
    handleGroupCart(handleConvertPromotionCart(cartData), true).forEach(
      (cartItem, index) => {
        console.log("cart", cartItem);
        let price =
          dineOption === "dinein"
            ? cartItem?.item.selling_uom_dtls[0].price_dtls[0].dine_in_price
            : cartItem?.item.selling_uom_dtls[0].price_dtls[0].takeaway_price;
        let parent_sno = i;
        if (cartItem.addOnValues.length) {
          let qty = cartItem.qty;
          while (qty > 0) {
            let sno = i;
            let parent_sno = i;
            salesDetails.push({
              s_no: sno++,
              ds_no: 1,
              parent_sno,
              seat_no: 1,
              qty: 1,
              uom_cf: 1,
              disc_type: "N",
              disc_value: 0,
              disc_amt: 0,
              disc_name: cartItem?.item?.promo_name || "",
              pro_disc_amt: 0,
              ...cartItem?.item,
              unit_price: cartItem?.price,
              price: cartItem?.price,
              sub_total: cartItem?.price * cartItem?.qty,
              svc_amt: config?.hasServiceCharge
                ? Number((price * cartItem.qty * 0.1).toFixed(2))
                : 0,
              is_apply_svc: 1,
              // tax_amt: Number(
              //   (price * cartItem.qty * (taxValue / 100)).toFixed(2)
              // ),
              tax_amt: "0.00",
              tax_rate: taxValue,
              tax_value: taxValue,
              is_absorbtax: 0,
              take_away_item: dineOption === "dinein" ? "N" : "Y",
              order_seq: 1,
              order_seq_type: "New",
              order_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
              print_flag: "N",
              item_kds_ready_status: "N",
              item_kds_ready_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
              item_kds_serve_status: "N",
              item_kds_serve_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
              override_f: 0,
              is_addon_enable: "N",
              add_on_name: "",
              c_userid: "WEBORDER",
              c_date: moment().format("YYYY/MM/DD HH:mm:ss"),
              m_userid: "WEBORDER",
              m_date: moment().format("YYYY/MM/DD HH:mm:ss"),
              menu_type: "",
              modifier_name: "",
              ref_1: "",
              ref_2: "",
              ref_3: "",
              ref_4: "",
              new_table_no: null,
              select_item: false,
              combo_qty: 1,
              combo_price: price + "",
              combo_total: price + "",
              checked: false,
              remarks: cartItem.remarks,
              $$hashKey: "object:3888",
            });
            const addOnSales = cartItem.addOnValues.map((addOn, subIndex) => {
              return {
                s_no: sno++,
                ds_no: subIndex + 2,
                parent_sno,
                seat_no: 1,
                qty: addOn?.modifier_qty ?? 1,
                uom_cf: 1,
                disc_type: "N",
                disc_value: 0,
                disc_name: "None",
                disc_amt: 0,
                pro_disc_amt: 0,
                category_code: cartItem.item.category_code,
                item_no: addOn.item_no,
                item_name: addOn.citem_name,
                item_desc: addOn.citem_name,
                sku_no: addOn.sku_no,
                uom: addOn.uom,
                unit_price: addOn.price_dtls + "",
                price: addOn.price_dtls + "",
                sub_total:
                  addOn.price_dtls === 0
                    ? 0
                    : addOn.price_dtls * addOn.modifier_qty,
                svc_amt: config?.hasServiceCharge
                  ? Number(
                      (addOn.price_dtls * addOn.modifier_qty * 0.1).toFixed(2)
                    )
                  : 0,
                is_apply_svc: 1,
                tax_amt: (
                  addOn.price_dtls *
                  addOn.modifier_qty *
                  (taxValue / 100)
                ).toFixed(2),
                tax_rate: taxValue,
                tax_value: taxValue,
                is_absorbtax: 0,
                take_away_item: dineOption === "dinein" ? "N" : "Y",
                order_seq: 1,
                order_seq_type: "New",
                order_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
                print_flag: "N",
                item_kds_ready_status: "N",
                item_kds_ready_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
                item_kds_serve_status: "N",
                item_kds_serve_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
                override_f: 0,
                is_addon_enable: "N",
                add_on_name: "",
                c_userid: "WEBORDER",
                c_date: moment().format("YYYY/MM/DD HH:mm:ss"),
                m_userid: "WEBORDER",
                m_date: moment().format("YYYY/MM/DD HH:mm:ss"),
                menu_type: "",
                modifier_name: addOn.modifier_name,
                ref_1: "",
                ref_2: "",
                ref_3: "",
                ref_4: "",
                new_table_no: null,
                select_item: false,
                combo_qty: 0,
                combo_price: 0,
                combo_total: 0,
                checked: false,
                remarks: "",
                $$hashKey: "object:3888",
              };
            });
            i = sno;
            salesDetails = [...salesDetails, ...addOnSales];
            qty--;
            parent_sno++;
          }
        } else {
          salesDetails.push({
            s_no: i++,
            ds_no: 1,
            parent_sno,
            seat_no: 1,
            qty: cartItem?.qty ?? 1,
            uom_cf: 1,
            disc_type: "N",
            disc_value: 0,
            disc_amt: 0,
            disc_name: "None",
            pro_disc_amt: 0,
            ...cartItem?.item,
            unit_price: "0.00",
            price: "0.00",
            // sub_total: price * cartItem.qty,
            sub_total: "0.00",
            svc_amt: config?.enableServiceCharge
              ? Number((price * cartItem.qty * 0.1).toFixed(2))
              : 0,
            is_apply_svc: 1,
            tax_amt: (price * cartItem.qty * (taxValue / 100)).toFixed(2),
            tax_rate: taxValue,
            tax_value: taxValue,
            is_absorbtax: 0,
            take_away_item: dineOption === "dinein" ? "N" : "Y",
            order_seq: 1,
            order_seq_type: "New",
            order_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
            print_flag: "N",
            item_kds_ready_status: "N",
            item_kds_ready_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
            item_kds_serve_status: "N",
            item_kds_serve_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
            override_f: 0,
            is_addon_enable: "N",
            add_on_name: "",
            c_userid: "WEBORDER",
            c_date: moment().format("YYYY/MM/DD HH:mm:ss"),
            m_userid: "WEBORDER",
            m_date: moment().format("YYYY/MM/DD HH:mm:ss"),
            menu_type: "",
            modifier_name: "",
            ref_1: "",
            ref_2: "",
            ref_3: "",
            ref_4: "",
            new_table_no: null,
            select_item: false,
            combo_qty: 0,
            combo_price: 0,
            combo_total: 0,
            checked: false,
            remarks: cartItem.remarks,
            $$hashKey: "object:3888",
          });
        }
      }
    );

    let orderMode = "";

    switch (type) {
      case "counter":
        orderMode = "PayAtCounter";
        break;

      case "card":
        orderMode = "PayFirst";
        break;

      case "qlubQR":
      case "paylater":
        orderMode = "PayLater";
        break;

      default:
        break;
    }

    const posPayload = {
      qr_code: localStorage.getItem(STORAGEKEY.QR_CODE),
      order_mode: orderMode,
      qr_type: config?.qr_type,
      comp_code: "01",
      store_name: localStorage.getItem(STORAGEKEY.STORE_NAME),
      doc_date: moment().format("YYYY/MM/DD HH:mm:ss"), // example 2021/01/01 00:00:00
      service_type: config?.is_quickservice
        ? "Q"
        : dineOption === "dinein"
        ? "E"
        : "T",
      service_type_info: dineOption === "dinein" ? "Dine In" : "Take Away",
      // localStorage.getItem("selectiDineOption") === "takeaway"
      //   ? "Takeaway"
      //   : "Dine In",
      order_status_id: "N",
      order_status_desc: "New",
      kitchen_status_id: "P",
      kitchen_status_desc: "In Progress",
      sub_total: total,
      total_svc: serviceTax,
      total_tax: config?.gstInclusive
        ? GSTInclusiveTaxValue
        : config?.enableGST
        ? GSTTaxValue
        : 0,
      net_amt: Number(newTotal),
      table_no: localStorage.getItem(STORAGEKEY.TABLE_NO),
      sales_service_dtls: config?.enableServiceCharge
        ? [
            {
              service_name: "SERVICE CHARGE",
              service_by: "P",
              service_value: 10,
              sales_amt: total,
              service_amt: serviceTax,
            },
          ]
        : [],
      sales_payment_dtls: [],
      sales_other_info: "",
      payment_register_name: config?.register_no,
      payment_shift_code: "SHIFT1",
      table_transfer: "N",
      c_date: moment().format("YYYY/MM/DD HH:mm:ss"),
      m_userid: "",
      m_date: moment().format("YYYY/MM/DD HH:mm:ss"),
      disc_type: "N",
      disc_value: 0,
      disc_amt: 0,
      total_disc: "0.00",
      round_adj_amt: "0.00",
      absorb_tax: "Y",
      register_name: config?.register_no,
      shift_code: "SHIFT1",
      absorb_tax_info: "",
      table_transfer_sno: "",
      sales_no: "",
      customer_code: "exempt",
      cust_addr_s_no: "",
      customer_name: "",
      card_no: "",
      dob: "",
      contact_no: "",
      email: "",
      address: "",
      postal_code: "",
      disc_name: "None",
      tips_amt: 0,
      total_tender_amt: "0.00",
      change_amt: 0,
      no_of_pax: 1,
      remarks: "",
      del_driver: "",
      ref_1: "",
      ref_2: "",
      ref_3: "",
      ref_4: "",
      ref_5: "",
      order_from: "TQR",
      c_userid: "",
      sales_dtls: salesDetails,
      //  Additional
      mode_of_order: dineOption === "dinein" ? "Dine In" : "Take Away",
      kitchen_remarks: "",
      delivery_datetime: "",
      pickup_datetime:
        dineOption !== "dinein"
          ? orderTime === "now"
            ? formatDate(new Date())
            : formatDate(new Date(orderTime))
          : "",
    };

    if (config?.is_quickservice) {
      posPayload.kds_status = "C";
    }

    const body = [];
    body.push(posPayload);

    console.log("body", body);
    try {
      const res = await PostSaleCreate(body);
      const result = res?.data?.data[0];

      await sendPosLog(result.sales_no, body?.[0]);

      if (result.result === "SUCCESS") {
        dispatcher(addSalesNo(result.sales_no));
        dispatcher(
          placeOrderSuccess({
            serviceTax,
            GSTTaxValue,
            order_status: "complete",
          })
        );
        setDisableButton(false);
        setIsLoading(false);

        if (usingOnlineLink) {
          const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);
          const tableNo = localStorage.getItem(STORAGEKEY.TABLE_NO);

          navigate("/payment-callback", {
            state: {
              redirectLink: `${process.env.REACT_APP_QLUB_PAYMENT}/?order_id=${result.sales_no}&restaurant_name=${storeName}&table_id=${tableNo}`,
            },
          });
        }
        return {
          success: true,
          message: result?.information,
        };
      } else {
        setIsErrorShow(true);
        setShowModal(false);
        setDisableButton(false);
        setErrorMessage(result?.information);
        setIsLoading(false);

        return {
          success: false,
          message: result?.information,
        };
      }
    } catch (err) {
      setIsLoading(false);

      return {
        success: false,
        message: err?.message,
      };
    }
  };
  const deleteCart = (uuid) => {
    const findUuidList =
      formatPromotionCartData?.find((record) => record?.uuid === uuid)
        ?.uuidList || [];

    findUuidList?.forEach((record) => {
      dispatcher(deleteCartItem(record));
    });
  };
  const redirectHomepage = () => {
    navigate("/home");
  };

  // fetch products from supabase
  const fetchSupabaseProducts = async () => {
    setIsLoading(true);
    const resp = await getProducts(process.env.REACT_APP_MODE);
    if (resp.data.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp.data.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks.map((item) => item.pos_item_no);
      setProductsSupabase(resp.data);
      setIsLoading(false);

      return topPicksIds;
    }
  };

  useEffect(() => {
    fetchSupabaseProducts();
  }, []);

  const handleSubmit = () => {
    if (config?.order_mode === "PayFirst") {
      setShowModal(true);
    } else {
      setShowPaylaterConfirmModal(true);
    }
  };

  const handlePaylaterConfirm = async () => {
    setSubmitButtonDisabled(true);
    setShowPaylaterConfirmModal(false);
    const resp = await paymentpage(false, "paylater");
    if (resp.success) {
      navigate("/payment-options", {
        state: {
          pm: "paylater",
        },
      });
    }
    setSubmitButtonDisabled(false);
  };

  useEffect(() => {
    if (formatPromotionCartData?.length > 0) {
      dispatcher(setTotal(formatPromotionCartData));
    }
  }, [formatPromotionCartData]);

  return (
    <>
      {isLoading && (
        <Spinner
          animation="border material-icons"
          className="loader-position z-3"
        />
      )}
      <div className="App">
        <div
          className="qr-before-body w-100 position-relative"
          style={{
            minHeight: "calc(100vh - 100px)",
          }}>
          <Header title="Cart">
            <div className="position-fixed w-100">
              <div className="d-flex  ps-4 pb-2 pe-3 pt-3 bg-white">
                <label className="recommendation-text">
                  RECOMMENDATIONS FOR YOU
                </label>
              </div>
              <div className="bg-white pb-3 ps-2 pe-2">
                <Recommendation
                  responsive={responsive}
                  productsSupabase={productsSupabase}
                />
              </div>
            </div>
          </Header>
          <div
            className="inner-div bottom-0 main-div w-100"
            style={{
              paddingTop: 240,
              overflowY: "auto",
              minHeight: "100dvh",
              height: "max-content",
            }}>
            {formatPromotionCartData && formatPromotionCartData.length !== 0 ? (
              <>
                {/* <div
                  className="d-flex bg-white justify-content-between pt-2 ps-4 pb-2 pe-3"
                  style={{ marginTop: "300px" }}
                >
                  <div className="d-grid">
                    <label className="dine-option-text mb-1 text-uppercase">
                      {localStorage.getItem("selectiDineOption") === "takeaway"
                        ? "Takeaway"
                        : "Dine In"}
                    </label>
                    <label className="table-text mb-1 text-uppercase">
                      Table-{localStorage.getItem(STORAGEKEY.TABLE_NO)}
                    </label>
                  </div>
                  <div className="option-icon">
                    <span className="material-icons mt-3"> restaurant </span>
                  </div>
                </div> */}
                <div className="d-flex justify-content-between ps-4 pb-2 pe-3 pt-3">
                  <label className="order-summary-text">ORDER SUMMARY</label>
                </div>
                {formatPromotionCartData &&
                  formatPromotionCartData.length > 0 &&
                  formatPromotionCartData.map((cartItem, index) => (
                    <CartItem
                      cartItem={handleConvertPromotionCartItem(cartItem)}
                      setConfirmData={setConfirmData}
                      deleteCart={deleteCart}
                      placeOrder={placeOrder}
                      decrementCounter={decrementCounter}
                      incrementCounter={incrementCounter}
                      productsSupabase={productsSupabase}
                    />
                  ))}

                <div className="d-flex  ps-4 pb-2 pe-3 pt-3">
                  <label className="payment-summart-text">
                    PAYMENT SUMMARY
                  </label>
                </div>
                <div className="bg-white d-flex justify-content-between p-4 text-start mb-3">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="sub-total-text pb-2">SUBTOTAL</td>
                        <td className="sub-total-text pb-2 text-end">
                          ${Number(total).toFixed(2)}
                        </td>
                      </tr>

                      {serviceTax > 0 && config?.enableServiceCharge && (
                        <tr>
                          <td className="sub-total-text pb-2">
                            SERVICE CHARGE (10%)
                          </td>
                          <td className="sub-total-text pb-2 text-end">
                            ${Number(serviceTax).toFixed(2)}
                          </td>
                        </tr>
                      )}

                      {GSTTaxValue > 0 && config?.enableGST && (
                        <tr>
                          <td className="sub-total-text pb-2">
                            GST {taxValue}% {gstInclusive ? "(Inclusive)" : ""}
                          </td>
                          <td className="sub-total-text pb-2 text-end">
                            $
                            {Number(
                              config?.gstInclusive
                                ? GSTInclusiveTaxValue
                                : showGSTTaxValue
                            ).toFixed(2)}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan={2}>
                          <hr className="dashed-border" />
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="public-sans-font fw-bold font-16 black-text line-height-21 pb-2 pt-2">
                          TOTAL
                        </td>
                        <td className="public-sans-font fw-bold font-16 black-text line-height-21 pb-2 pt-2 text-end">
                          $
                          {(
                            Number(total) +
                            Number(serviceTax) +
                            Number(GSTTaxValue)
                          ).toFixed(2)}
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td
                          colSpan={2}
                          className="public-sans-font fw-normal font-16 black-text line-height-17 pt-2"
                        >
                          If you continue, you accept our{" "}
                          <Link
                            to="/profile"
                            className="privact-txt text-decoration-underline"
                          >
                            Privacy Policy
                          </Link>{" "}
                          and our{" "}
                          <Link
                            to="/profile"
                            className="privact-txt text-decoration-underline"
                          >
                            {" "}
                            Terms of Services
                          </Link>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div style={{ height: "60vh" }}>
                <img
                  src={`/assets/commonImages/${config?.EmptyImage}.png`}
                  alt="empty Screen"
                  className="mt-5 pt-4"
                />
                <div className="d-grid mt-3 me-3 ms-3">
                  <label className="empty-cart-text mb-1 text-uppercase">
                    Your Cart is Empty
                  </label>
                  {/* <label className="public-sans-font fw-bold font-16 black-text line-height-20 ms-3 me-3 text-uppercase">
                    Looks like you haven't made your choice yet
                  </label> */}
                </div>
                <Button
                  className="fill-btn menu-btn w-50 mt-4"
                  type="submit"
                  onClick={redirectHomepage}>
                  Explore our Menu
                </Button>
              </div>
            )}
          </div>
          <div className="set-width bottom-0 bg-white cart-button-div pt-1 pb-1 ps-3 pe-3 position-absolute z-50">
            {isErrorShow && (
              <p className="error-text text-danger text-start">
                {errorMessage}
              </p>
            )}

            {/* <Button
                    className="fill-green-btn cart-btn mt-2 public-sans-font fw-bold font-16 line-height-21 letter-spacing-02"
                    type="submit"
                    onClick={() => setShowModal(true)}
                  >
                    Place Order
                  </Button> */}

            <button
              disabled={
                submitButtonDisabled || formatPromotionCartData?.length === 0
              }
              onClick={handleSubmit}
              className="w-100 fill-btn sticky-btn mb-2 mt-1">
              <div className="d-flex align-items-center justify-content-between w-100 text-white px-2">
                <span className="place-order-text">Submit Order</span>
                <span className="place-order-text">
                  Items: {cartItemsCount}
                </span>

                <span className="place-order-text rounded-circle">
                  ${handleConvertPromotionTotal(Number(newTotal)).toFixed(2)}
                </span>
              </div>
            </button>

            <ConfirmationModal
              showModal={showModal}
              setShowModal={setShowModal}
              setDisableButton={setDisableButton}
              disableButton={disableButton}
              paymentpage={paymentpage}
            />
          </div>
        </div>
      </div>
      {showModalOpen ? (
        <CustomizeModal
          showModal={showModalOpen}
          setShowModal={setShowModalOpen}
          showModalData={showModalData}
        />
      ) : (
        ""
      )}
      <ConfirmModal
        open={confirmData?.open}
        title={confirmData?.title}
        description={confirmData?.description}
        onConfirm={confirmData?.onConfirm}
        onCancel={() => setConfirmData({})}
      />

      {showPaylaterConfirmModal && (
        <ConfirmModal
          open={showPaylaterConfirmModal}
          title={"Confirm Order"}
          description={
            "Please note that orders submitted cannot be changed nor cancelled"
          }
          onConfirm={handlePaylaterConfirm}
          onCancel={() => setShowPaylaterConfirmModal(false)}
        />
      )}
    </>
  );
}
export default Cart;
