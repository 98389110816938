function Device() {
  return (
    <>
      <div className="qr-large-device-frame position-fixed start-50 top-50 w-100">
        <img
          className="w-100"
          src={`/assets/commonImages/frame/frame6.png`}
          alt="Mobile Frame"
        />
      </div>
    </>
  );
}
export default Device;
