import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useBrandConfig from "../../hooks/useBrandConfig";
import { RiCloseCircleFill } from "react-icons/ri";
import { STORAGEKEY } from "../../constants";

const WelcomeBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const [countDown, setCountDown] = useState(5);

  const { welcomeBanner } = useBrandConfig();

  const onClose = () => {
    setShowModal(false);
    localStorage.setItem(STORAGEKEY.WELCOME_BANNER, false);
  };

  useEffect(() => {
    if (
      welcomeBanner &&
      !Boolean(localStorage.getItem(STORAGEKEY.WELCOME_BANNER))
    ) {
      setShowModal(true);
      localStorage.setItem(STORAGEKEY.WELCOME_BANNER, true);
    }
  }, [welcomeBanner]);

  useEffect(() => {
    if (showModal) {
      const interval = setInterval(() => {
        if (countDown === 0) {
          onClose();
          return;
        }

        setCountDown(countDown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countDown, showModal]);

  return (
    <Modal
      show={showModal}
      onHide={onClose}
      centered
      className="welcome-banner">
      <Modal.Body className="d-flex flex-column">
        <RiCloseCircleFill
          onClick={onClose}
          className="text-white align-self-end"
          style={{
            fontSize: 24,
          }}
        />
        <img src={welcomeBanner} alt="" className="w-100 h-100" />
      </Modal.Body>
    </Modal>
  );
};

export default WelcomeBanner;
