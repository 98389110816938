import React, { useEffect, useState } from "react";
import { AnimateOnChange } from "react-animation";
import { useNavigate } from "react-router-dom";
import {
  QRCodeValidation,
  generateQRCode,
  storeMenuButton,
  storeMenuItems,
  storeStockList,
  userSessionId,
} from "../../services/tableQRServices";
import { STORAGEKEY } from "../../constants";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../redux/cartSlice";
import { getProducts } from "../../services/supabase/product";
import {
  resetPosState,
  setPosFullItemList,
  setPosMenuButton,
  setPosStoreStockList,
  setSupabaseProducts,
} from "../../redux/posSlice";

function SplashScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = window.location.search;
  const [loading, setLoading] = useState(true);
  const [isQRError, setIsQRError] = useState(false);

  const { posStoreStockList } = useSelector((state) => state.pos);

  let storeName = new URLSearchParams(params).get("outlet");
  let tableNo = new URLSearchParams(params).get("table_no");
  const qrCode = new URLSearchParams(params).get("QRCode");

  // temp support
  const storeNameTemp = new URLSearchParams(params).get("storename");
  if (storeNameTemp && storeNameTemp.length > 0) {
    storeName = storeNameTemp;
  }
  const tableNoTemp = new URLSearchParams(params).get("tableno");
  if (tableNoTemp && tableNoTemp.length > 0) {
    tableNo = tableNoTemp;
  }

  // ? condition redirect after splash time over
  const QRCodeValidationFunction = (qrCode, tableNo) => {
    QRCodeValidation(qrCode, tableNo)
      .then((res) => {
        const result = res?.data?.data[0];
        console.log("result", result);
        if (result.result === "FAIL") {
          setIsQRError(true);
          navigate("/qr-not-valid");
        } else {
          navigate("/home");
        }
      })
      .catch(() => {});
  };

  const sessionCheckFunction = () => {
    if (
      // qrCode &&
      // qrCode.length > 0 &&
      // process.env.REACT_APP_MODE !== "pavilion"
      qrCode &&
      qrCode.length > 0
    ) {
      QRCodeValidationFunction(qrCode, tableNo);
    } else if (
      // (!qrCode || qrCode.length <= 0) &&
      // process.env.REACT_APP_MODE === "pavilion"
      !qrCode ||
      qrCode.length <= 0
    ) {
      navigate("/home");
    } else {
      navigate("/qr-not-valid");
    }
  };

  const handleLocalStorage = async () => {
    dispatch(resetState());
    dispatch(resetPosState());
    localStorage.clear();

    // clear dining mode after each scan
    localStorage.setItem(STORAGEKEY.DINING_MODE, "");

    localStorage.setItem(
      STORAGEKEY.STORE_NAME,
      storeName && storeName.length > 0 ? storeName : "BUGIS JUNCTION"
    );
    localStorage.setItem(STORAGEKEY.QR_CODE, qrCode);
    localStorage.setItem(STORAGEKEY.TABLE_NO, tableNo || "ZD999");

    await fetchSupabaseProducts();
  };

  const fetchSupabaseProducts = async () => {
    const resp = await getProducts(process.env.REACT_APP_MODE);

    if (resp?.data?.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp?.data?.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks?.map((item) => item.pos_item_no);

      dispatch(setSupabaseProducts(topPicksIds));
    }
  };

  useEffect(() => {
    // if (process.env.REACT_APP_MODE !== "gochiso") {
    //   setTimeout(() => {
    //     sessionCheckFunction();
    //     // if (
    //     //   localStorage.getItem(STORAGEKEY.STORE_NAME) &&
    //     //   localStorage.getItem(STORAGEKEY.TABLE_NO) !== "null" &&
    //     //   localStorage.getItem(STORAGEKEY.TABLE_NO) !== null &&
    //     //   localStorage.getItem(STORAGEKEY.QR_CODE) !== "null" &&
    //     //   localStorage.getItem(STORAGEKEY.QR_CODE) !== null &&
    //     //   localStorage.getItem(STORAGEKEY.SESSION_ID)
    //     // ) {
    //     //   setLoading(false);
    //     //   console.log("---------------", isQRError);
    //     //   if (!isQRError) {
    //     //     // navigate("/home");
    //     //   }
    //     // } else {
    //     //   navigate("/qr-not-valid");
    //     // }
    //     // if (isQRError) {
    //     //   navigate("/qr-not-valid");
    //     // }
    //   }, 3500);
    // }

    // set the session id

    if (!storeName) {
      navigate("/storename-not-valid");
    } else {
      handleLocalStorage();
      setLoading(true);
      userSessionId()
        .then((res) => {
          const sessionId = res?.data?.session_id;

          localStorage.setItem(STORAGEKEY.SESSION_ID, sessionId);

          setLoading(false);
        })
        .catch((_err) => {
          setLoading(false);
        });
    }
  }, []);

  const formik = useFormik({
    initialValues: { terms_services: false, promotional: true },
    // validationSchema: Yup.object({
    //   terms_services: Yup.boolean().oneOf(
    //     [true],
    //     "The terms of service must be accepted"
    //   ),
    //   // promotional: Yup.boolean().oneOf(
    //   //   [true],
    //   //   "I do not wish to receive any marketing or promotional materials must be accepted"
    //   // ),
    // }),
    onSubmit: async (values) => {
      sessionCheckFunction();
      localStorage.setItem(
        STORAGEKEY.SESSION_TIMEOUT,
        new Date().toISOString()
      );

      navigate("/home");
    },
  });
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="title-bg position-absolute w-100 start-0 z-index-1">
            {/* {process.env.REACT_APP_MODE === "pavilion" && (
              <label className="position-absolute start-0 end-0 title-margin font-28 fw-bold line-height-45 hotel-title">
                The Authentic <br />
                South Indian Restaurant
              </label>
            )} */}
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
              alt=""
              className="splash-logo-img mt-3 pt-2"
            />
          </div>
          <div className="h-50 position-absolute w-100 d-flex flex-column justify-content-end pb-5">
            <div className="terms-service">
              <Form className="" onSubmit={formik.handleSubmit}>
                <button
                  type="submit"
                  disabled={!formik.isValid || loading}
                  className="continue-as-guest d-flex justify-content-center text-white align-items-center mb-2 mt-4 me-3 ms-3">
                  Continue as Guest
                </button>
                <div className="pt-2 pb-2 ms-3 me-4 d-flex ps-4">
                  <span className="splash-text text-start text-black">
                    By clicking "Continue as Guest" I agree to Solution Details'{" "}
                    <strong>
                      <Link to="/terms-of-use" className="text-black">
                        Terms of Use
                      </Link>
                    </strong>{" "}
                    and{" "}
                    <strong>
                      <Link to="/privacy-policy" className="text-black">
                        Privacy Policy.
                      </Link>
                    </strong>
                  </span>
                </div>
                {/* <Form.Group>
                    <div className="pb-2 d-flex ms-3 me-4">
                      <Form.Check
                        type="checkbox"
                        id="promotional"
                        name="promotional"
                        value={`promotional`}
                        label=""
                        onChange={formik.handleChange}
                      />
                      <span className="splash-text text-start text-black">
                        I do not wish to receive any marketing or promotional
                        materials.
                      </span>
                    </div>
                  </Form.Group> */}
              </Form>
            </div>
            <div className="powered-by-evolut position-fixed d-flex justify-content-center bottom-0 align-items-center">
              <span>
                Powered by Solution Details - Evolut POS & Intelli Table QR
              </span>
            </div>
          </div>
          <img
            src={`/assets/${process.env.REACT_APP_MODE}/background/splash.png`}
            alt=""
            className="splash-img w-100"
          />
        </div>
      </div>
    </>
  );
}

export default SplashScreen;
