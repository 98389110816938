export const formatCents = (cents) => {
  const formattedCent = new Intl.NumberFormat("en-SG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "SGD",
  }).format(cents);

  return formattedCent;
};
