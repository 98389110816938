import React from "react";
import config from "../../config/config";

function StoreNameError() {
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="inner-div main-div position-absolute w-100">
            <div className="pt-1 mt-5 d-flex justify-content-center align-items-center flex-column">
              <span className="qr-time-out-text text-danger">Error</span>
              <img
                src={`/assets/commonImages/${config?.qr_code_error}.png`}
                alt=""
                className="mt-1"
              />
            </div>
            <div className="d-grid">
              <label className="mt-4 qr-desc  text-center ms-5 me-5">
                Error loading the app
              </label>
              <label className="mt-3 qr-aprroach text-center ms-5 me-5">
                Please scan the QR code again
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StoreNameError;
