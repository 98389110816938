import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import useBrandConfig from "../../hooks/useBrandConfig";
import { useLocation } from "react-router-dom";

const PaymentCallback = () => {
  const { config } = useBrandConfig();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.redirectLink) {
      window.location.href = state?.redirectLink;
    }
  }, [state?.redirectLink]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="inner-div main-div position-absolute w-100">
            <div className="pt-5 mt-4">
              <img
                src={
                  config?.thank_you_banner ||
                  `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`
                }
                alt=""
                className="mt-5"
                width={300}
                height={300}
              />
            </div>
            <div className="d-grid p-3">
              <label className="thank-text mt-4 text-center">
                Thank you for your order. Please wait while you are being
                redirected for payment
              </label>
              <div
                className="flex justify-content-center align-items-center"
                style={{
                  marginTop: 80,
                }}>
                <Spinner animation="border material-icons" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCallback;
