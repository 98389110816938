import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

function MenuItems({
  menuDishes,
  placeOrder,
  onDetailOpen,
  productsSupabase,
  category,
}) {
  const cartData = useSelector((state) => state.cart.cart);

  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`;

  const [imageURL, setImageURL] = useState(defaultImage);
  const [loading, setLoading] = useState(true);

  const cartItemData = cartData.filter(
    (item) => menuDishes.item_no === item.id
  );

  const findProduct = productsSupabase?.find(
    (record) => record?.name === menuDishes?.item_name
  );

  const originalImageUrl = findProduct?.image_url;

  useEffect(() => {
    setLoading(true);

    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageURL(originalImageUrl);
      } else {
        setImageURL(defaultImage);
      }
    });

    setLoading(false);
  }, [defaultImage, originalImageUrl]);

  return (
    <>
      {/* <div id='menuitem' class="menuitem p-2 mb-2 rounded" style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)' }}> */}
      <div
        className="menuitem rounded d-flex flex-column rounded position-relative"
        style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)" }}>
        <div
          style={{
            height: "10px",
            position: "absolute",
            top: "-250px",
          }}
          id={`subCategory-${category}`}
        />
        {!loading && (
          <div className="menuitem-image p-2">
            <img
              src={imageURL}
              className="carousel-cls"
              alt=""
              onClick={() => onDetailOpen(menuDishes)}
            />
          </div>
        )}
        <div
          id="menuitem"
          className="menuitem-container h-100 p-2 mb-2 d-flex flex-column pt-0">
          {menuDishes.is_top_pick === true ? (
            <div className="position-absolute d-flex best-seller-tag ms-2">
              <span className="best-seller-text text-white">Top Pick!</span>
            </div>
          ) : (
            ""
          )}
          <div className="d-grid text-start mb-4">
            <span className="menu-title mt-3 text-break text-uppercase">
              {menuDishes.item_desc}
              {menuDishes.bal_qty > 0 ? (
                <span className="bal-qty-text mt-2">
                  ({menuDishes.bal_qty - (cartItemData[0]?.qty || 0)} left)
                </span>
              ) : (
                ""
              )}
            </span>
            <div className="d-flex align-items-center gap-2 mt-2">
              <span className="menu-price-text">
                $
                {Number(
                  menuDishes?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
                ).toFixed(2)}
              </span>
              {findProduct?.dummy_price && (
                <span className="dummy-price-text">
                  ${Number(findProduct?.dummy_price).toFixed(2)}
                </span>
              )}
            </div>
          </div>
          {menuDishes.is_soldout === "Y" ||
          !menuDishes.is_soldout ||
          menuDishes.is_emenu_disable === "Y" ||
          !menuDishes.is_emenu_disable ||
          (menuDishes.is_avl_limit_check === "Y" && menuDishes.bal_qty === 0) ||
          !menuDishes.is_avl_limit_check ? (
            <button className="sold-out-btn mb-2 mt-4" disabled>
              SOLD OUT
            </button>
          ) : (
            <>
              {cartItemData &&
              cartItemData.length > 0 &&
              cartItemData[0].qty >= menuDishes.bal_qty &&
              menuDishes.bal_qty !== 0 ? (
                <button className="sold-out-btn mb-2 mt-auto flex-end" disabled>
                  ADD
                </button>
              ) : (
                <button
                  className="border-btn add-cart-btn d-flex justify-content-center align-items-center shaking-button mb-2 mt-auto flex-end"
                  onClick={() => placeOrder(menuDishes)}>
                  ADD
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MenuItems;
